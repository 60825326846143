<template>
    <el-input :size="size" :value="value2" readonly="readonly"  suffix-icon="el-icon-time" ></el-input>    
</template>
<script>
export default {
    name:'l-modifytime',
    props: {
        value:String,
        size:String,
    },
    watch: {
        value:{
            handler(){
                this.value2 = this.$getDayTime()
            },
            immediate: true
        }
    },
    computed:{
        value2:{
            get(){
                return this.value
            },
            set(val){
                this.$emit('input',val)
            }
        }
    }
}
</script>