<template>
    <div :class="['l-rblock','l-data-panel',{'l-data-panel-app':isApp}]" >
        <div class="l-data-panel--title" >
            {{label}}
            <button v-if="isMore" @click="handleClick" type="button" class="el-button el-button--text">
                <span v-if="!isApp" >更多</span>
                <i v-else class="el-icon-arrow-right" ></i>
            </button>
        </div>
        <div class="l-rblock" >
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name:'l-data-panel',
    props: {
        label:String,
        isMore:Boolean,
        isApp:Boolean
    },
    data(){
        return {
        }
    },
    computed:{
    },
    methods:{
        handleClick(){
            this.$emit('more')
        }
    }
}
</script>

<style lang="less">
@import './index.less';
</style>
