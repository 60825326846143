<template>
  <el-cascader
    ref="cascader"
    class="l-cascader"
    v-model="newValue"
    :size="size"
    :options="options"
    :props="getProps()"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    :filterable="filterable"
    :showAllLevels="showAllLevels"
    :collapseTags="collapseTags"
    :separator="separator"
    :popperClass="popperClass"
    :debounce="debounce"
    :beforeFilter="beforeFilter"
    :filterMethod="filterMethod"
    @change="onChange"
    @expand-change="onExpandChange"
  >
  </el-cascader>
</template>
<script>
import { validatenull } from "@util/validate";
export default {
  name: "l-cascader",
  props: {
    value: {},
    placeholder: String,
    idKey: {
      type: String,
      default: ""
    },
    pidKey: {
      type: String,
      default: ""
    },
    separator: {
      type: String,
      default: "/"
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    childrenKey: {
      type: String,
      default: "children"
    },
    disabledKey: {
      type: String,
      default: "disabled"
    },
    leafKey: {
      type: String,
      default: "leaf"
    },
    size: String,
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    showAllLevels: {
      type: Boolean,
      default: true
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fullPath: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    },
    lazyLoad: Function,
    filterMethod: Function,
    beforeFilter: Function,
    popperClass: String,
    trigger: String,
    debounce: Number
  },
  created() {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    newValue: {
      get() {
        let val = this.value;
        if (!validatenull(val)) {
          val = (val + "").split(",");
          if (this.fullPath) {
            val = val.map((item) => {
              return item.split(this.separator);
            });
          }
        } else {
          val = [];
        }
        return this.multiple ? val : val[0];
      },
      set(val) {
        val = this.getValue(val);
        let label;
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs && this.$refs.cascader) {
              label = this.multiple
                ? this.$refs.cascader.presentText
                : this.$refs.cascader.inputValue;
            }
            this.$emit("update:label", label);
          });
        });
        this.$emit("input", val);
      }
    }
  },
  methods: {
    getProps() {
      return {
        expandTrigger: this.trigger,
        multiple: this.multiple,
        emitPath: this.fullPath,
        label: this.labelKey,
        value: this.valueKey,
        children: this.childrenKey,
        disabled: this.disabledKey,
        leaf: this.leafKey,
        lazy: this.lazy,
        lazyLoad: this.lazyLoad
      };
    },
    getValue(val = this.value) {
      if (this.multiple) {
        if (this.fullPath) {
          val = val.map((item) => {
            return item.join(this.separator);
          });
        }
        val = String(val);
      } else {
        if (this.fullPath) {
          val = val.join(this.separator);
        } else {
          val = String(val);
        }
      }
      return val;
    },
    onChange(val) {
      val = this.getValue(val);
      this.$emit("change", val);
    },
    onExpandChange() {
      this.$emit("expandChange", ...arguments);
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
