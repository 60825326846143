<template>
  <div class="l-query" :style="{ height: `${myHeight}px` }">
    <div class="l-rblock">
      <el-form
        :model="formData"
        size="mini"
        :label-width="`${labelWidth}px`"
        ref="form"
      >
        <input type="text" style="display: none" />
        <el-col v-if="isKeywordSearch" :span="span">
          <el-form-item label="关键词" prop="keyword">
            <el-input v-model="myKeyword" placeholder="请输入关键词"></el-input>
          </el-form-item>
        </el-col>
        <template v-for="(item, index) in myitems">
          <el-col :span="span" :key="index">
            <el-form-item
              :label="item.label"
              :ref="item.prop"
              :prop="item.prop"
            >
              <slot :name="item.prop"></slot>
            </el-form-item>
          </el-col>
        </template>
      </el-form>
    </div>

    <div v-if="showBtn" class="l-query-btns">
      <el-button @click="handleResetClick" size="mini">重置</el-button>
      <el-button
        @click="handleSearchClick"
        size="mini"
        type="primary"
        :loading="loading"
        >查询</el-button
      >
      <el-button
        @click="hanleOpenClick"
        v-if="items.length > 2"
        size="mini"
        type="text"
        :icon="this.isOpened ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        >{{ this.isOpened ? "收起" : "展开" }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "l-query",
  props: {
    labelWidth: {
      type: Number,
      default: 88,
    },
    span: {
      type: Number,
      default: 8,
    },
    items: {
      type: Array,
      default: () => [],
    },
    height: Number,
    formData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isResetSearch: {
      type: Boolean,
      default: true,
    },
    isKeywordSearch: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      itemMaps: {},
      isOpened: false,
      myHeight: 44,
      myKeyword: "",
    };
  },
  mounted() {},
  computed: {
    myitems() {
      let len = this.items.length;
      if (len <= this.wnum - 1) {
        return this.items;
      }
      if (this.isOpened) {
        return this.items;
      } else {
        const list = [];
        this.items.forEach((item, index) => {
          if (index < this.wnum - 1) {
            list.push(item);
          } else {
            return false;
          }
        });

        return list;
      }
    },
    wnum() {
      return 24 / this.span;
    },
    showBtn() {
      return this.isKeywordSearch || this.myitems.length;
    },
  },
  methods: {
    hanleOpenClick() {
      this.isOpened = !this.isOpened;
      if (this.isOpened) {
        this.myHeight =
          Math.floor(this.items.length / this.wnum) * 46 + 16 + 28;
      } else {
        this.myHeight = 44;
      }
      this.$emit("update:height", this.myHeight);
    },
    handleResetClick() {
      this.$refs.form && this.$refs.form.resetFields();
      this.$emit("update:keyword", "");
      if (this.isResetSearch) {
        this.$nextTick(() => {
          this.handleSearchClick();
        });
      }
    },
    handleSearchClick() {
      if (this.isKeywordSearch) {
        this.$emit("update:keyword", this.myKeyword);
      }
      this.$emit("search", this.$deepClone(this.formData));
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
