<template>
    <div class="l-rblock" ref="main" ></div> 
</template>
<script>
export default {
    name:'l-echarts',
    props: {
        option:Object
    },
    data(){
        return {
            isChart:null
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init () {
            // 判断是否引入echart包
            if (!window.echarts) {
                console.error('learun[力软信息]提醒你:缺少 echarts 引入');
                return
            }
            const main = this.$refs.main
            if (main) {
                this.myChart = window.echarts.init(main)
                setTimeout(()=>{
                    this.updateChart(this.option)
                },100)
                /*this.$nextTick(()=>{
                    this.updateChart(this.option)
                })*/
            }
        },
        updateChart(option){
            this.myChart.resize()
            option && this.myChart.setOption(option,true)
        }
    },
    beforeDestroy(){
        this.myChart.dispose()
        this.myChart = null
    }
}
</script>