<template>
  <div>
    <el-input
      class="l-layer-select"
      ref="main"
      :placeholder="$t(placeholder)"
      v-model="showValue"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @clear="handleInputClear"
      @focus="handleShow"
    >
      <span slot="append" @click="handleShow">
        <el-button slot="append" icon="el-icon-more"></el-button>
      </span>
    </el-input>
    <l-dialog
      :title="$t(placeholder)"
      :visible.sync="dialogVisible"
      :height="height"
      :width="width"
      :hasBtns="multiple"
      @ok="handleSelectMultiple"
      @opened="handleDialogOpened"
    >
      <l-layout class="l-tab-page">
        <l-select-panel
          v-model="value2"
          isChangeSearch
          ref="selectPanel"
          :valueKey="getValueKey"
          model="client"
          :columns="myColumns"
          :selectedData.sync="selectedData"
          :multiple="multiple"
          :isPage="isPage"
          :loadSelectTable="loadSelectTable"
          @rowClick="handleSelectPanel"
          style="padding: 8px"
        >
        </l-select-panel>
      </l-layout>
    </l-dialog>
  </div>
</template>
<script>
export default {
  name: "l-layer-select",
  props: {
    value: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: "请选择"
    },
    size: String,
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 504
    },
    width: {
      type: Number,
      default: 960
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isPage: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    idKey: {
      type: String,
      default: ""
    },
    pidKey: {
      type: String,
      default: ""
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    dataLabelKey: {
      type: String,
      default: ""
    },
    dataValueKey: {
      type: String,
      default: ""
    },
    apiCode: {
      type: String,
      default: ""
    },
    loadPageData: Function
  },
  data() {
    return {
      dialogVisible: false,
      selectedData: [],
      value2: "",
      label: void 0,
      api: {}
    };
  },
  computed: {
    showValue() {
      this.refreshLabelValue();
      return this.label != null ? this.label : this.value;
    },
    myColumns() {
      return this.columns.filter((t) => !t.hidden);
    },
    getValueKey() {
      return this.dataValueKey || this.valueKey;
    },
    getLabelKey() {
      return this.dataLabelKey || this.labelKey;
    }
    /*myOptions(){
              return this.options.map((t,index)=>({...t,learun_value:index + ''}))
          },*/
  },
  mounted() {
    //this.loadSelectTable({ rows: 20, page: 1, keyword: "" });
  },
  watch: {
    value: {
      handler(val) {
        if (this.loadPageData) {
          this.label = val;
        } else {
          this.value2 = val;

          this.refreshLabelValue(val);
        }
      },
      immediate: true
    },
    apiCode: {
      handler(val) {
        if (val) {
          this.getApi(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    refreshLabelValue(val = this.value) {
      const values = (val && val.split(",")) || [];
      const options = this.options || [];
      this.label = options
        .filter((item) => values.includes(item[this.getValueKey]))
        .map((item) => {
          return item[this.getLabelKey];
        })
        .join(",");
    },
    handleInputClear() {
      this.$refs.selectPanel && this.$refs.selectPanel.handleClear();
      this.handleChange();
    },
    handleChange(val) {
      this.label = val ? val[this.getLabelKey] : "";
      this.$emit("input", val);
      this.$emit("change", val);
    },
    handleShow() {
      if (!this.disabled) {
        this.dialogVisible = true;
      }
    },

    async getShowName() {
      const apiDbsource = window.$api.data.dbsource;
      const result = await this.$awaitWraper(
        apiDbsource.apiParams(
          `${this.api.f_Url}`,
          this.api.f_RequestMethod || "get",
          {
            [this.getValueKey]: this.value2
          }
        )
      );
      if (result.rows && result.rows.length) {
        this.label = result.rows
          .map((item) => {
            return item[this.getLabelKey];
          })
          .join(",");
      } else {
        this.label = "";
      }
    },
    async getApi(val) {
      const apiDbsource = window.$api.data.dbsource;
      this.api = await this.$awaitWraper(
        apiDbsource.apiParams(`${window.$apiUrl}data/datainterface/${val}`)
      );
      if (this.value && this.getValueKey) {
        this.getShowName();
      }
    },

    tableLoadData() {
      this.$refs.selectPanel.init();
    },
    loadSelectTable(postData) {
      return new Promise((resolve) => {
        if (this.apiCode) {
          const apiDbsource = window.$api.data.dbsource;
          this.$awaitWraper(
            apiDbsource.apiParams(
              this.api.f_Url,
              this.api.f_RequestMethod || "get",
              postData
            )
          ).then((result) => {
            resolve({
              rows: result.rows ? this.arraytoLowerCase(result.rows) : [],
              records: result.records
            });
          });
        } else if (this.loadPageData) {
          this.loadPageData(postData).then((res) => {
            resolve({
              rows: res.rows ? res.rows : [],
              records: res.records
            });
          });
        } else {
          if (this.options.length == 0) {
            resolve({
              rows: []
            });
          } else {
            const data = this.options.filter((t) => {
              let res = false;
              for (const col of this.myColumns) {
                const item = (t[col.prop] || "") + "";
                if (item.indexOf(postData.keyword) != -1) {
                  res = true;
                  break;
                }
              }
              return res;
            });
            if (this.isPage) {
              const rows = this.$pagination(postData.page, postData.rows, data);

              resolve({
                rows: rows,
                records: data.length
              });
            } else {
              resolve({
                rows: data
              });
            }
          }
        }
      });
    },

    handleDialogOpened() {
      this.tableLoadData();
    },
    handleSelectPanel(row) {
      this.$emit("input", this.value2);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.$nextTick(() => {
        this.dialogVisible = false;
      });
      this.handleChange(row);
    },
    handleSelectMultiple() {
      this.label = this.selectedData
        .map((row) => {
          return row[this.getLabelKey];
        })
        .join(",");
      this.$emit("input", this.value2);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;
    },
    arraytoLowerCase(data) {
      if (Array.isArray(data)) {
        return data.map((item) => {
          if (Object.prototype.toString.call(item) == "[object Object]") {
            return Object.keys(item).reduce((acc, cur) => {
              acc[cur.toLowerCase()] = item[cur];
              return acc;
            }, {});
          }
          return item;
        });
      }
      return data;
    }
  }
};
</script>
