<template>
  <l-data-panel
    :label="label"
    :isMore="isMore"
    :isApp="isApp"
    @more="handleMore"
  >
    <div class="l-data-list l-rblock">
      <template v-if="data != null && data.length > 0">
        <div
          @click="handleClick(item)"
          class="l-data-list--line"
          v-for="(item, index) in data"
          :key="index"
        >
          <div
            class="l-data-list--item"
            :style="{ width: col.width + '%', 'text-align': col.align }"
            v-for="(col, index2) in columns"
            :key="col.prop + index2"
          >
            <span
              v-if="
                ![
                  'icon',
                  'rate',
                  'switch',
                  'slider',
                  'color',
                  'file',
                  'img',
                ].includes(col.dataType)
              "
            >
              {{ columnText(item, col) }}
            </span>
            <l-value-to-label
              v-else
              :value="item[col.prop]"
              :type="col.dataType"
              :code="col.dataCode"
              :valueKey="col.valueKey"
              :labelKey="col.labelKey"
              :options="col.options"
              :format="col.format"
              :setting="col.setting"
            ></l-value-to-label>
          </div>
        </div>
      </template>
      <div v-else-if="isApp" class="l-rblock l-empty">暂无数据</div>
      <el-empty v-else description="暂无数据" :image-size="64"></el-empty>
    </div>
  </l-data-panel>
</template>
<script>
export default {
  name: "l-data-list",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    label: String,
    columns: {
      type: Array,
      default: () => [],
    },
    isMore: Boolean,
    isApp: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleMore() {
      this.$emit("more");
    },
    handleClick(item) {
      this.$emit("itemClick", item);
    },
    columnText(row, column) {
      this.loadCellData(row[column.prop], column);
      return this.getCellText(row[column.prop], column);
    },
    loadCellData(value, { dataType, dataCode }) {
      if (dataType && value) {
        switch (dataType) {
          case "dataItem":
            this.lr_loadDataItem && this.lr_loadDataItem(dataCode);
            break;
          case "dataSource":
            this.lr_loadDataSourceData && this.lr_loadDataSourceData(dataCode);
            break;
          case "company":
            this.lr_loadCompanys && this.lr_loadCompanys();
            break;
          case "department":
            this.lr_loadDepartment && this.lr_loadDepartment(value);
            break;
          case "role":
            this.lr_loadRole && this.lr_loadRole(value);
            break;
          case "post":
            this.lr_loadPost && this.lr_loadPost(value);
            break;
          case "user":
            this.lr_loadUsers && this.lr_loadUsers(value);
            break;
          case "areas":
            if (this.lr_loadAreas) {
              const areaList = value.split(",");
              if (areaList.length == 3) {
                this.lr_loadAreas(0),
                  this.lr_loadAreas(areaList[0]),
                  this.lr_loadAreas(areaList[1]);
              }
            }
            break;
        }
      }
    },
    getCellText(
      value,
      { dataType, dataCode, options, valueKey, labelKey, format, prop }
    ) {
      if (window.$validatenull(value)) {
        return "";
      }
      if (!this.lr_dataSourceName) {
        return value;
      }
      switch (dataType) {
        case "mydata": {
          const dataSource = [];
          this.findTreeItem(options, dataSource); //静态数据
          return this.lr_dataSourceName(
            dataSource,
            value,
            "value",
            "label",
            prop
          );
        }
        case "dataItem":
          return this.lr_dataItemName(this.lr_dataItem[dataCode], value);
        case "dataSource":
          return this.lr_dataSourceName(
            this.lr_dataSourceData[dataCode],
            value,
            valueKey,
            labelKey
          );
        case "datetime":
          return window.$formatDate(value, format || "yyyy-MM-dd hh:mm:ss");
        case "company":
          return this.lr_loadCompanyName(value);
        case "department":
          return this.lr_departmentNameByOne(value);
        case "role":
          return (this.lr_role[value] || {})[labelKey || "f_FullName"] || "";
        case "post":
          return (this.lr_post[value] || {})[labelKey || "f_FullName"] || "";
        case "user":
          return this.lr_userName(value);
        case "areas":
          return this.lr_areasName(value);
        default:
          return value;
      }
    },
    findTreeItem(arr, res) {
      arr.forEach((t) => {
        res.push({ value: t.value, label: t.label });
        if (t.children) {
          this.findTreeItem(t.children, res);
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
