<template>
  <el-input
    :value="value2"
    readonly="readonly"
    class="l-code is-lookonly"
  ></el-input>
</template>
<script>
export default {
  name: "l-code",
  props: {
    value: String,
    code: String,
    message: {
      type: String,
      default: "自动生成"
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (this.$validatenull(newVal)) {
          this.setCodeOld();
        }
      },
      immediate: true
    }
  },
  computed: {
    value2: {
      get() {
        if (
          this.$validatenull(this.value) ||
          this.value.indexOf("learun_code_") != -1
        ) {
          return this.message;
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    setCode() {
      this.value2 = `learun_code_${this.code}|${this.$uuid()}`;
      /*if(!this.$validatenull(this.code) && this.lr_getCode){
                this.value2 = await this.lr_getCode(this.code)
            }*/
    },
    async setCodeOld() {
      if (!this.$validatenull(this.code) && this.lr_getCode) {
        this.value2 = await this.lr_getCode(this.code);
      } else {
        this.value2 = `${this.$uuid()}`;
      }
    }
  }
};
</script>
