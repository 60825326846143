<template>
    <el-input :size="size" :value="value2" readonly="readonly"  suffix-icon="fa fa-sitemap" ></el-input>    
</template>
<script>
export default {
    name:'l-company',
    props: {
        value:String,
        size:String,
    },
    watch: {
         value:{
            handler(newVal){
                if(this.$validatenull(newVal) && this.loginInfo){
                    this.value2 = this.loginInfo.f_CompanyId
                }
            },
            immediate: true
        }
    },
    computed:{
        value2:{
            get(){
                if(this.$validatenull(this.value)){
                    return ''
                }
                if(this.lr_companyList){
                    return (this.lr_companyList.find(t=>t.f_CompanyId == this.value) || {} ).f_FullName || ''
                }
                else{
                    return ''
                }
            },
            set(val){
                this.$emit('input',val)
            }
        }
    },
    created(){
        this.lr_loadCompanys && this.lr_loadCompanys()
    },
}
</script>