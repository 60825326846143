<template>
    <el-button size="mini" type="text">{{getName()}}</el-button>
</template>
<script>
export default {
    name:'l-user',
    props: {
        value:String,
    },
    methods:{
        getName(){
            if(this.lr_users){
                this.lr_loadUsers(this.value)
                const user = this.lr_users[this.value]
                if(user){
                    let res =  user.f_RealName
                    if(user.f_DepartmentId){
                        this.lr_loadDepartment(user.f_DepartmentId)
                        const department = this.lr_department[user.f_DepartmentId]
                        if(department){
                            res = `${department.f_FullName}-${res}`
                        }
                    }
                    return res
                }
                else{
                    return ' '
                }
            }
            else{
                return ''
            }
        }
    }
}
</script>