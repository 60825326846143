<template>
  <el-drawer
    :append-to-body="appendToBody"
    :visible="visible"
    :direction="direction"
    :size="mySize"
    :withHeader="false"
    :wrapperClosable="wrapperClosable"
    :destroy-on-close="destroyOnClose"
    :modal="modal"
    :before-close="beforeClose"
    :close-on-press-escape="closeOnPressEscape"
    custom-class="l-drawer"
    @open="openDrawer"
    @opened="openedDrawer"
    @close="closeDrawer"
    @closed="closedDrawer"
  >
    <div
      :class="['l-drawer--warpper', customClass]"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      <div
        class="l-drawer--body"
        :style="{ 'padding-bottom': showClose || showOk ? '48px' : '0' }"
      >
        <el-input style="position: absolute; top: -200px"></el-input>
        <div class="l-rblock">
          <slot></slot>
        </div>
        <div class="l-drawer-bottom-btns" v-if="showClose || showOk">
          <el-button v-if="showClose" size="mini" @click="handleClose">{{
            closeBtnLabel
          }}</el-button>
          <slot name="btns"> </slot>
          <el-button
            v-if="showOk"
            size="mini"
            type="primary"
            @click="handleOk"
            >{{ okBtnLabel }}</el-button
          >
        </div>
      </div>
      <div v-if="withHeader" class="l-drawer--header">
        <slot name="header">
          <div class="l-drawer--title">{{ title }}</div>
          <div class="l-drawer--btns">
            <el-button
              size="mini"
              type="text"
              style="font-size: 18px"
              icon="el-icon-close"
              @click="handleClose"
            ></el-button>
          </div>
        </slot>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: "l-drawer",
  props: {
    title: String,
    visible: {
      type: Boolean
    },
    direction: {
      type: String,
      default: "rtl",
      validator(val) {
        return ["ltr", "rtl", "ttb", "btt"].indexOf(val) !== -1;
      }
    },
    size: {
      type: String,
      default: "600px"
    },
    width: Number,
    modal: {
      type: Boolean,
      default: true
    },
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    withHeader: {
      type: Boolean,
      default: true
    },

    showClose: {
      type: Boolean,
      default: true
    },
    showOk: {
      type: Boolean,
      default: true
    },
    okBtnLabel: {
      type: String,
      default: "确定"
    },
    closeBtnLabel: {
      type: String,
      default: "取消"
    },
    customClass: String,
    appendToBody: {
      type: Boolean,
      default: true
    },
    beforeClose: Function,
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    wrapperClosable: {
      type: Boolean,
      default: true
    },

    isStep: {
      type: Boolean,
      default: false
    },
    stepActive: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    },
    validateSteps: Function
  },
  data() {
    return {
      loading: false,
      loadingText: ""
    };
  },
  mounted() {},
  computed: {
    mySize() {
      return this.width ? `${this.width}px` : this.size;
    }
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleOk() {
      this.$emit("ok", this.showLoading, this.hideLoading);
    },
    closeDrawer() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    closedDrawer() {
      this.$emit("closed");
    },
    openDrawer() {
      this.$emit("open");
    },
    openedDrawer() {
      this.$emit("opened", this.showLoading, this.hideLoading);
    },
    showLoading(text) {
      this.loadingText = text || "保存数据中...";
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
