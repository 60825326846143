<template>
  <div
    ref="main"
    class="l-query2"
    @[getKeyupName()].enter.stop="handleSearchClick"
  >
    <el-form :model="formData" size="mini" :inline="true" ref="form">
      <input type="text" style="display: none" />
      <el-form-item v-if="isKeywordSearch" label="关键词" prop="keyword">
        <el-input v-model="keyword" placeholder="请输入关键词"></el-input>
      </el-form-item>
      <template v-for="(item, index) in showItems">
        <el-form-item
          :label="item.label"
          :ref="item.prop"
          :prop="item.prop"
          :key="index"
        >
          <slot :name="item.prop"></slot>
        </el-form-item>
      </template>
      <el-form-item v-if="showBtn">
        <el-button
          style="margin-right: 8px"
          size="mini"
          type="text"
          icon="el-icon-arrow-down"
          v-if="showMore"
          @click="popoverVisible = !popoverVisible"
        >
          {{ popoverVisible ? "收起条件" : "更多条件" }}
        </el-button>
        <el-button class="--reset" @click="handleResetClick" size="mini"
          >重置</el-button
        >
        <el-button
          class="--search"
          @click="handleSearchClick"
          size="mini"
          type="primary"
          :loading="loading"
          :style="{ width: this.isresize ? '80px' : '' }"
          >查询</el-button
        >
      </el-form-item>
    </el-form>

    <l-drawer
      title="更多条件"
      :width="popoverWidth"
      :showClose="false"
      okBtnLabel="查询"
      v-if="showMore"
      :visible.sync="popoverVisible"
      @ok="handleSearchClick"
    >
      <div class="l-query-popper">
        <el-form
          :model="formData"
          size="mini"
          ref="form2"
          :label-width="`${labelWidth}px`"
        >
          <el-form-item v-if="isKeywordSearch" label="关键词" prop="keyword">
            <el-input v-model="myKeyword" placeholder="请输入关键词"></el-input>
          </el-form-item>
          <template v-for="(item, index) in hideItems">
            <el-col :span="item.span || 24" :key="index">
              <el-form-item
                :label="item.label"
                :ref="item.prop"
                :prop="item.prop"
              >
                <slot :name="item.prop"></slot>
              </el-form-item>
            </el-col>
          </template>
        </el-form>
      </div>
    </l-drawer>
  </div>
</template>
<script>
var elementResizeDetectorMaker = require("element-resize-detector");
import { isIE } from "../../_util/util";

export default {
  name: "l-query2",
  props: {
    labelWidth: {
      type: Number,
      default: 120
    },
    popoverWidth: {
      type: Number,
      default: 600
    },
    items: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    isEnterSearch: {
      type: Boolean,
      default: true
    },
    isKeywordSearch: {
      type: Boolean,
      default: false
    },
    isShowDrawer: {
      type: Boolean,
      default: true
    },
    keyword: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showNum: this.items.length,
      erdUltraFast: null,
      isresize: false,
      showMore: false,
      popoverVisible: false,
      formDataInit: this.$deepClone(this.formData),
      myKeyword: ""
    };
  },
  watch: {
    formData: {
      handler() {},
      deep: true
    }
  },
  mounted() {
    let now;
    this.isShowDrawer &&
      this.$nextTick(() => {
        this.erdUltraFast = elementResizeDetectorMaker({
          strategy: "scroll" //<- For ultra performance.
        });
        this.erdUltraFast.listenTo(this.$refs.main, () => {
          const delayTime = isIE() ? 5000 : 500;
          if (!now || new Date().getTime() - now.getTime() > delayTime) {
            this.dolayout();
            now = new Date();
          }
        });
      });
  },
  beforeDestroy() {
    this.erdUltraFast.removeAllListeners(this.$refs.main);
    this.erdUltraFast.uninstall(this.$refs.main);
    this.erdUltraFast = null;
  },
  computed: {
    showItems() {
      let len = this.items.length;
      if (len <= this.showNum) {
        return this.items;
      } else {
        const list = [];
        this.items.forEach((item, index) => {
          if (index < this.showNum) {
            list.push(item);
          } else {
            return false;
          }
        });
        return list;
      }
    },
    hideItems() {
      let len = this.items.length;
      if (len <= this.showNum) {
        return [];
      } else {
        const list = [];
        this.items.forEach((item, index) => {
          if (index >= this.showNum) {
            list.push(item);
          }
        });
        return list;
      }
    },
    showBtn() {
      return this.isKeywordSearch || this.items.length;
    }
  },
  methods: {
    getKeyupName() {
      return this.isEnterSearch ? "keyup" : "";
    },
    handleResetClick() {
      this.$emit("update:keyword", "");
      this.items.forEach((item) => {
        let id = item.prop;
        if (this.formDataInit[id] != null) {
          this.$set(this.formData, id, this.formDataInit[id]);
        } else if (id in this.formData) {
          this.$set(this.formData, id, void 0);
        }
      });

      this.$nextTick(() => {
        this.handleSearchClick();
      });
      this.$emit("reset");
    },
    handleSearchClick() {
      this.popoverVisible = false;
      if (this.isKeywordSearch) {
        this.$emit("update:keyword", this.myKeyword);
      }
      this.$emit("search", this.$deepClone(this.formData));
    },
    resize(isLast) {
      this.$nextTick(() => {
        // 1.获取表单的高度
        let height = this.$getElemHeight(this.$refs.form.$el);
        if (height > 46 && this.showNum > 0) {
          this.showNum--;
          this.resize(isLast);
        } else {
          if (this.items.length > this.showNum && !isLast) {
            this.showMore = true;
            this.resize(true);
          } else {
            this.isresize = false;
          }
        }
      });
    },
    dolayout() {
      this.showNum = this.items.length;
      this.popoverVisible = false;
      if (!this.isresize) {
        this.isresize = true;
        this.showMore = false;
        this.resize(false);
      }
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
