<template>
  <div
    v-loading="loading"
    element-loading-text="数据加载中..."
    element-loading-spinner="el-icon-loading"
    :style="{ height: height }"
    :class="['l-edit-table', { '--auto-height': isAutoHeight }]"
  >
    <el-table
      v-if="isRender"
      :data="filterDataSource"
      style="width: 100%"
      size="mini"
      :height="height"
      :max-height="maxHeight"
      :stripe="stripe"
      :border="border"
      :shadow="shadow"
      :fit="fit"
      :show-header="showHeader"
      :highlight-current-row="highlightCurrentRow"
      :current-row-key="currentRowKey"
      :row-key="rowKey"
      :row-class-name="rowClassName"
      :row-style="getRowStyle()"
      :cell-class-name="cellClassName"
      :cell-style="getCellStyle()"
      :header-row-class-name="headerRowClassName"
      :header-row-style="headerRowStyle"
      :header-cell-class-name="getHeaderCellClassName"
      :header-cell-style="getHeaderCellStyle"
      :default-expand-all="defaultExpandAll"
      :expand-row-keys="expandRowKeys"
      :default-sort="defaultSort"
      :tooltip-effect="tooltipEffect"
      :show-summary="showSummary"
      :sum-text-colspan="sumTextColspan"
      :sum-text="sumText"
      :summary-method="summaryMethod"
      :span-method="spanMethod"
      :select-on-indeterminate="selectOnIndeterminate"
      :indent="indent"
      :lazy="lazy"
      :load="load"
      :tree-props="treeProps"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @selection-change="selectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @cell-click="cellClick"
      @cell-dblclick="onCellDblclick"
      @row-click="onRowClick"
      @row-contextmenu="rowContextmenu"
      @row-dblclick="onRowDblclick"
      @header-click="headerClick"
      @header-contextmenu="headerContextmenu"
      @sort-change="sortableChange"
      @filter-change="filterChange"
      @current-change="currentChange"
      @header-dragend="headerDagend"
      @expand-change="expandChange"
      ref="learunTable"
    >
      <slot name="beforeColumns"></slot>
      <!-- 删除数据行  -->
      <el-table-column
        v-if="hasRemove && !isRead"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--remove'
          )
        "
        align="center"
        width="40"
        :fixed="removeFixed"
      >
        <template slot-scope="scope">
          <button
            v-if="hasDeleteBtn(scope.row)"
            style="color: #f56c6c"
            type="button"
            @click.stop="handleDelete(scope.$index, scope.row)"
            class="el-button el-button--text el-button--mini"
          >
            <i class="el-icon-delete"></i>
          </button>
        </template>
      </el-table-column>
      <!-- 拖动排序  -->
      <el-table-column
        :class-name="
          getColumnClassNames(
            moveableColumnClassName,
            'el-table-column--moveable'
          )
        "
        align="center"
        width="45"
        v-if="IsSort"
        :fixed="moveableFixed"
      >
        <template slot="header">
          <i class="el-icon-sort" />
        </template>
        <template>
          <span class="learun-table__drag-handler">
            <i class="el-icon-sort" />
          </span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="isMultiSelect"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--multi-select'
          )
        "
        :fixed="isFixed"
        align="center"
        type="selection"
        width="45"
        header-align="center"
      ></el-table-column>

      <el-table-column
        v-if="isSingleSelect"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--single-select'
          )
        "
        :fixed="isFixed"
        align="center"
        width="45"
        header-align="center"
      >
        <template v-slot="scope">
          <el-radio
            class="el-table-column__radio"
            :value="getRadioValue(scope.row, scope.$index)"
            @input="(val) => setRadioValue(val, scope.row, scope.$index)"
            :label="getRadioLabel(scope.row, scope.$index)"
            @click.native.stop=""
            >{{ "" }}
          </el-radio>
        </template>
      </el-table-column>

      <el-table-column
        v-if="IsNum"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--num'
          )
        "
        align="center"
        label="序号"
        type="index"
        :index="indexMethod"
        :fixed="getNumFixed()"
      >
      </el-table-column>
      <slot name="innerColumns"></slot>
      <template v-for="(col, index) in columns">
        <el-table-column
          v-if="isShowColumn(col)"
          :class-name="getColumnClassName({ column: col, columnIndex: index })"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :align="col.align"
          :width="col.width"
          :show-overflow-tooltip="col.isNotAutoWrap == true ? true : false"
          :show-overflow-header-tooltip="isHeaderTooltip(col)"
          :fixed="getColumnFixed(col)"
          :filters="getColumnFilters(col)"
          :filter-method="getColumnFilterMethod(col)"
          :sortable="col.sortable != null ? col.sortable : sortable"
          :min-width="col.minWidth"
          :header-align="col.headerAlign"
        >
          <template v-slot:header="{ column }">
            <el-tooltip
              v-if="isHeaderTooltip(col, column)"
              effect="light"
              :content="column.label"
            >
              <div class="ellipsis">
                <span v-if="isRequired(col)" style="color: red">*</span
                ><span>{{ column.label }}</span>
              </div>
            </el-tooltip>
            <template v-else>
              <span v-if="isRequired(col)" style="color: red">*</span
              ><span>{{ column.label }}</span>
            </template>
          </template>
          <template v-slot="scope">
            <el-tooltip
              v-if="
                isCurrentEditRow(scope.row, col, scope) &&
                (isTooltipRequired(col, scope.row[col.prop]) ||
                  isColumnTooltip(col, scope))
              "
              effect="light"
              class="l-edit-table__column__tooltip"
              :class="{
                'is-valid':
                  (!isTooltipRequired(col, scope.row[col.prop]) &&
                    isColumnTooltip(col, scope)) ||
                  isValidValue(scope.row[col.prop]),
                ellipsis:
                  (!isTooltipRequired(col, scope.row[col.prop]) ||
                    isValidValue(scope.row[col.prop])) &&
                  isColumnTooltip(col, scope)
              }"
              :popper-class="{
                'l-edit-table__column__tooltip__popper': true,
                'is-valid':
                  (!isTooltipRequired(col, scope.row[col.prop]) &&
                    isColumnTooltip(col, scope)) ||
                  isValidValue(scope.row[col.prop]),
                ellipsis:
                  (!isTooltipRequired(col, scope.row[col.prop]) ||
                    isValidValue(scope.row[col.prop])) &&
                  isColumnTooltip(col, scope)
              }"
              :content="
                (!isTooltipRequired(col, scope.row[col.prop]) ||
                  isValidValue(scope.row[col.prop])) &&
                isColumnTooltip(col, scope)
                  ? columnText(scope.$index, scope.row, col, scope)
                  : getTooltipContent(col)
              "
            >
              <slot v-bind="scope" :name="col.prop">
                <span>{{
                  columnText(scope.$index, scope.row, col, scope)
                }}</span>
              </slot>
              <!-- <slot v-bind="scope" :name="col.prop">
                ^^{{ scope.row[col.prop] }}
              </slot> -->
              <!-- <slot v-bind="scope" :name="col.prop">
                **{{ scope.row[col.prop] }}
              </slot> -->
              <!-- <template v-else> @@{{ scope.row[col.prop] }} </template> -->
              <!-- <template>@@{{ scope.row[col.prop] }}</template> -->
            </el-tooltip>
            <slot
              v-else-if="isCurrentEditRow(scope.row, col, scope)"
              v-bind="scope"
              :name="col.prop"
            >
              {{ columnText(scope.$index, scope.row, col, scope) }}
            </slot>
            <!-- <template v-else>
              ++++{{ columnText(scope.$index, scope.row, col, scope) }}
            </template> -->

            <template v-else>
              <el-tooltip
                effect="light"
                class="ellipsis"
                :content="columnText(scope.$index, scope.row, col, scope)"
                v-if="isColumnTooltip(col, scope)"
              >
                <span>
                  {{ columnText(scope.$index, scope.row, col, scope) }}
                </span>
              </el-tooltip>
              <div v-else>
                {{ columnText(scope.$index, scope.row, col, scope) }}
              </div>
            </template>
          </template>
        </el-table-column>
      </template>
      <slot></slot>
      <slot name="afterColumns"></slot>
    </el-table>

    <el-button-group class="l-edit-table__btns">
      <slot name="beforeBtns"></slot>
      <slot name="btns">
        <template v-for="btn in ExtendsBtns">
          <el-upload
            class="__upload"
            v-if="isShowUpload && showBtn(btn) && btn.component === 'el-upload'"
            :key="btn.id"
            action="#"
            :limit="1"
            :show-file-list="false"
            :http-request="(data) => handleTableBtnClick(btn, data)"
          >
            <el-button
              class="l-edit-table__btn"
              :type="btn.type ? btn.type : 'text'"
              size="mini"
              :key="btn.id"
              :icon="btn.icon"
              plain
            >
              {{ $t(getBtnLabel(btn)) }}
            </el-button>
          </el-upload>
          <el-button
            class="l-edit-table__btn"
            v-if="showBtn(btn) && btn.component !== 'el-upload'"
            :key="btn.id"
            :type="btn.type ? btn.type : 'text'"
            size="mini"
            :icon="btn.icon"
            plain
            @click="(e) => handleTableBtnClick(btn)"
          >
            {{ $t(getBtnLabel(btn)) }}
          </el-button>
        </template>
        <el-button
          v-if="isAdd && !isRead && hasPageAuth(addId, 'buttons')"
          class="l-edit-table__btn --add"
          type="primary"
          size="mini"
          :icon="addIcon"
          plain
          @click="handleAdd()"
        >
          {{ $t(addText) }}
        </el-button>
      </slot>
      <slot name="afterBtns"></slot>
    </el-button-group>

    <l-drawer
      :title="$t('表格列设置')"
      :visible.sync="columnsVisible"
      :showOk="false"
      :showClose="false"
      @close="handleCloseColumnsSetting"
      :wrapperClosable="true"
      size="600px"
    >
      <columns-setting
        v-model="columns"
        @change="handleColumnsSettingChange"
        @moveableChange="handleColumnsSettingMoveableChange"
      ></columns-setting>
    </l-drawer>
  </div>
</template>
<script>
import ColumnsSetting from "../../lrTable/_src/columnsSetting.vue";
import tableEvent from "@util/tableEvent";
export default {
  name: "l-edit-table",
  mixins: [tableEvent()],
  components: {
    ColumnsSetting
    //dynamicColumn
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    height: {
      type: [String, Number]
    },
    maxHeight: {
      type: [String, Number]
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    stripe: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    fit: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },

    currentRowKey: [String, Number],
    // rowKey: [String, Function],

    // rowClassName: [String, Function],
    // cellClassName: [String, Function],
    // headerRowClassName: [String, Function],
    // headerCellClassName: [String, Function],
    // headerRowStyle: [String, Function],
    // headerCellStyle: [String, Function],
    // cellStyle: [Function, Object],
    tooltip: {
      type: Boolean,
      default: false
    },

    defaultExpandAll: Boolean,
    expandRowKeys: {
      type: Array
    },

    defaultSort: Object,

    tooltipEffect: String,

    showSummary: {
      type: Boolean,
      default: false
    },
    sumText: {
      type: String,
      default: "合计"
    },
    summaryMethod: Function,

    spanMethod: Function,

    selectOnIndeterminate: {
      type: Boolean,
      default: true
    },

    extendsBtns: {
      type: Array,
      default: () => []
    },

    indent: {
      type: Number,
      default: 16
    },
    lazy: Boolean,
    load: Function,
    treeProps: Object,

    isPage: {
      type: Boolean,
      default: false
    },
    pageSizes: Array,
    pageTotal: {
      type: Number,
      default: 0
    },
    dblclickEdit: {
      type: Boolean,
      default: false
    },
    addText: {
      type: String,
      default: "新增" //新增一行
    },
    addId: {
      type: String,
      default: "Add"
    },
    addIcon: {
      type: String,
      default: "el-icon-plus"
    },
    hasAdd: {
      type: Boolean,
      default: true
    },
    hasRemove: {
      type: Boolean,
      default: true
    },
    // addBtnText: {
    //   type: String,
    //   default: "新增一行" //新增一行
    // },
    // isAddBtn: {
    //   type: Boolean,
    //   default: true
    // },
    // isRemoveBtn: {
    //   type: Boolean,
    //   default: true
    // },
    removeFixed: {
      type: String,
      default: "left"
    },
    required: {
      type: Boolean,
      default: false
    },
    isRead: {
      type: Boolean,
      default: false
    },
    filterDeleteBtn: Function
  },
  data() {
    return {
      // currentRow: null,
      currentEditRow: null,
      currentEditRowIndex: -1,
      isShowUpload: true
    };
  },
  mounted() {
    this.setSort();
  },
  computed: {
    // isFixed() {
    //   return (
    //     this.columns &&
    //     this.columns.find((item) => {
    //       return item.fixed == true;
    //     }) != undefined &&
    //     !this.isMoveable
    //   );
    // },
    isAutoHeight() {
      return this.autoHeight === true;
    },
    // filterDataSource() {
    //   return this.dataSource.filter((t) => !t || !t.isHide);
    // },
    ExtendsBtns() {
      return this.extendsBtns.filter((btn) =>
        this.hasPageAuth(btn.id, "buttons")
      );
    },
    isAdd() {
      return this.hasAdd;
    }
  },
  methods: {
    hasPageAuth(id, group) {
      if (this.lr_hasPageAuth) {
        this.lr_hasPageAuth(id, group);
      }
      return true;
    },
    getBtnLabel(btn) {
      if (btn) {
        const btnLabel = btn.label;
        try {
          if (btnLabel && btnLabel.call) {
            return btnLabel();
          }
        } catch {}
        return btnLabel;
      }
    },
    handleTableBtnClick(btn = {}) {
      this.$emit("extends-btns", btn);
      this.$emit("click", btn);
      if (btn.component === "el-upload") {
        this.isShowUpload = false;
      }
      const btnId = btn.id;
      if (btnId) {
        const clickEvent = btnId.toLowerCase()[0] + btnId.substring(1);
        const newArguments = [...arguments].slice(1);
        this.$emit(clickEvent, ...newArguments);
      }
      this.$nextTick(() => {
        if (btn.component === "el-upload") {
          this.isShowUpload = true;
        }
      });
    },

    showBtn(btn) {
      if (btn) {
        try {
          const btnShow = btn.show;
          const btnHide = btn.hide;
          if (btnShow != null) {
            if (btnShow.call) {
              return !!btnShow(btn);
            } else {
              return !!btnShow;
            }
          } else if (btnHide != null) {
            if (btnHide.call) {
              return !btnHide(btn);
            } else {
              return !btnHide;
            }
          }
        } catch {}
      }
      return true;
    },

    onCellDblclick(row, column, cell, event) {
      if (this.dblclickEdit && cell) {
        this.$nextTick(() => {
          setTimeout(() => {
            const cellInput = cell.querySelector("input");
            if (cellInput) {
              if (cellInput.select) {
                cellInput.select();
              } else if (cellInput.focus()) {
                cellInput.focus();
              }
            }
          });
        });
      }
      this.cellDblclick(row, column, cell, event);
    },
    onRowDblclick(row, column, event) {
      const target = event.target;
      const targetParent = target && target.parentNode;
      // this.setCurrentRow(row);
      if (this.dblclickEdit) {
        if (
          this.currentEditRow === row &&
          (this.currentEditRow !== row ||
            target.classList.contains("cell") ||
            targetParent.classList.contains("cell"))
        ) {
          this.clearCurrentEditRow();
        } else {
          this.setCurrentEditRow(row);
        }
      }
      this.rowDblclick(row, column, event);
      // this.rowClick(row);
    },
    // getCurrentEditRow() {
    //   return this.currentEditRow;
    // },
    // setCurrentEditRow(row) {
    //   this.currentEditRow = row;
    // },
    getCurrentEditRow() {
      return this.currentEditRow;
    },
    getCurrentEditRowIndex() {
      return this.currentEditRowIndex;
    },
    setCurrentEditRow(row) {
      this.currentEditRow = row;
      this.currentEditRowIndex = this.dataSource.indexOf(this.currentEditRow);
    },
    setCurrentEditRowIndex(index) {
      let row = this.dataSource[index];
      row = row != null ? row : null;
      this.setCurrentEditRow(row);
    },
    clearCurrentEditRow() {
      this.setCurrentEditRow(null);
    },
    initScopeRowVars(scope) {
      scope._isCurrentEditRow = this.isCurrentEditRow(
        scope.row,
        scope.column,
        scope
      );
      scope._isTooltipRequired = this.isTooltipRequired(scope.column);
    },
    isCurrentEditRow(row, column, scope) {
      const currentEditRow = this.currentEditRow;
      const isColumnDblclickEdit = this.isColumnDblclickEdit(column);
      scope.$edit = !!currentEditRow && currentEditRow === row;
      const isCurrentEditRow =
        !isColumnDblclickEdit || (!!currentEditRow && currentEditRow === row);
      return isCurrentEditRow;
    },
    isTooltipRequired(column) {
      return this.isRequired(column);
    },
    isValidValue(columnValue) {
      return !this.$validatenull(columnValue);
    },
    getTooltipContent(column) {
      return column.message || `${column.label || column.property}必填`;
    },
    isShowColumn(item) {
      const isShowColumn = item.hide !== true && item.isHidden !== true;
      if (isShowColumn && this.lr_hasPageColumn) {
        return this.lr_hasPageColumn(item.prop);
      }
      return isShowColumn;
    },
    isColumnDblclickEdit(column) {
      const isDblclickEdit =
        column.dblclickEdit != null ? column.dblclickEdit : this.dblclickEdit;
      return isDblclickEdit;
    },
    indexMethod(index) {
      return index + 1;
    },
    handleDelete(index, row) {
      this.setCurrentRow(null);
      this.$emit("delete", {
        index: index,
        row: row,
        data: this.dataSource
      });
    },
    handleAdd() {
      const dataSource = [...this.dataSource];
      // this.currentEditRow = null;
      this.$emit("addRow", { data: this.dataSource });
      this.$emit("add", { data: this.dataSource });
      const newDataSource = [...this.dataSource];
      if (newDataSource) {
        newDataSource.forEach((newData) => {
          if (!dataSource.find((data) => data === newData)) {
            this.setCurrentEditRow(newData);
          }
        });
      }
    },
    hasDeleteBtn(row) {
      if (this.filterDeleteBtn) {
        return this.filterDeleteBtn(row);
      } else {
        return true;
      }
    }
  }
};
</script>
<style lang="less">
@import "./index.less";

.l-edit-table {
  &__btns {
    // background: red;
    // height: 100px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  &__btn {
    // flex: auto;
  }
}
</style>
