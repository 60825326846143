<template>
    <el-input :size="size" :value="name" readonly="readonly" suffix-icon="fa fa-sitemap" ></el-input>    
</template>
<script>
export default {
    name:'l-department',
    props: {
        value:String,
        size:String,
    },
    watch: {
        value:{
            handler(newVal){
                if(!this.$validatenull(newVal)){
                    this.lr_loadDepartment && this.lr_loadDepartment(newVal)
                }

                if(this.$validatenull(newVal) && this.loginInfo && this.loginInfo.f_DepartmentId){
                    this.lr_loadDepartment && this.lr_loadDepartment(this.loginInfo.f_DepartmentId)
                    this.$emit('input',this.loginInfo.f_DepartmentId)
                }
            },
            immediate: true
        }
    },
    computed:{
        name(){
            if(this.$validatenull(this.value)){
                return ''
            }
            if(this.lr_department){
                return (this.lr_department[this.value] || {}).f_FullName || ''
            }
            else{
                return ''
            }
        },
    }
}
</script>