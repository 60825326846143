<template>
  <div>
    <el-input
      ref="main"
      :placeholder="$t(placeholder)"
      :value="midValue.address"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @clear="handleInputClear"
      @focus="handleShow"
    >
      <span slot="append" @click="handleShow">
        <el-button slot="append" icon="el-icon-location-information"></el-button>
      </span>
    </el-input>
    <l-dialog
      :title="$t('地址选择')"
      :visible.sync="dialogVisible"
      :height="height"
      :width="width"
      @ok="handleOK"
      @opened="handleDialogOpened"
    >
    <div class="l-rblock" style="padding-top:40px;" >
      <div class="l-BMap-top" >
          <el-autocomplete
            v-model="addressInput"
            style="width: 100%"
            popper-class="l-BMap-autoAddress"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="true"
            placeholder="请填写详细地址"
            clearable
            size="mini"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <i class="el-icon-search fl mgr10" />
              <div style="overflow: hidden">
                <div class="title">{{ item.title }}</div>
                <div class="address ellipsis">{{ item.address }}</div>
              </div>
            </template>
          </el-autocomplete>
      </div>
      <l-BMap-panel ref="bmapPanel"
        :address.sync="addressInput"
        :lng.sync="lng"
        :lat.sync="lat"
      >
      </l-BMap-panel>
    </div>
    
    </l-dialog>
  </div>
</template>
<script>
export default {
  name: "l-BMap-select",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    size: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 504,
    },
    width: {
      type: Number,
      default: 960,
    }
  },
  data() {
    return {
      dialogVisible: false,
      addressInput:'',
      lng:0, // 精度
      lat:0, // 维度
    };
  },
  computed: {
    midValue(){
      //准备地图需要的json格式
      if (this.$validatenull(this.value)) {
        return {
          address: "",
          lng: 0,
          lat: 0
        }
      }
      return {
        address: this.value.split(",")[0],
        lng: parseFloat(this.value.split(",")[1]),
        lat: parseFloat(this.value.split(",")[2])
      };
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
    handleShow() {
      if (!this.disabled) {
        this.dialogVisible = true;
      }
    },
    handleDialogOpened() {
      this.addressInput = this.midValue.address
      this.lng =  this.midValue.lng
      this.lat =  this.midValue.lat
      this.$nextTick(async ()=>{
        await this.$refs.bmapPanel.initMap()
      })
    },
    handleOK() {
      this.$emit("input",`${this.addressInput},${this.lng},${this.lat}`);
      this.dialogVisible = false;
      this.handleChange({
        address:this.addressInput,
        lng:this.lng,
        lat:this.lat 
      })
    },
    querySearchAsync(str, cb){
      this.$refs.bmapPanel.querySearchAsync(str, cb)
    },
    // 7-2、选择地址
    handleSelect(item) {
      this.addressInput =`${item.address}${item.title}`;
      this.lng = item.point.lng;
      this.lat = item.point.lat;
      this.$refs.bmapPanel.setPoint(item.point)
    },
    handleInputClear(){
      this.$emit("input",undefined)
      this.$emit("change",undefined)
    }
  }
};
</script>

<style lang="less">
@import './index.less';
</style>