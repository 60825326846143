<template>
  <el-select
    ref="main"
    class="l-tree-select"
    :size="size"
    :clearable="clearable"
    popper-class="l-tree-select-popper"
    :value="labelShow"
    :placeholder="placeholder"
    @clear="handleClear"
    :disabled="disabled"
    @click.native="initScroll"
  >
    <div v-if="filter" style="padding: 8px 8px 0 8px; margin: 0">
      <el-input
        size="mini"
        placeholder="输入关键字进行过滤"
        v-model="filterText"
      ></el-input>
    </div>
    <!-- ~~{{ value2 }}~+{{ labelText }}+~##{{ defaultExpandedKeys }}###~~~~ -->
    <el-option :value="value2">
      <el-tree
        ref="tree"
        style="padding: 8px 0"
        class="l-tree-option"
        :filter-node-method="filterNode"
        :expand-on-click-node="false"
        :data="options"
        :default-expand-all="defaultExpandAll && !lazy"
        :defaultExpandedKeys="defaultExpandedKeys"
        :lazy="lazy"
        :load="handleTreeLoad"
        :node-key="valueKey"
        :current-node-key="value2"
        :props="treeProps"
        @node-click.self="handleNodeClick"
        @check="handleNodeCheck"
      >
        <span class="l-tree-node" slot-scope="{ node, data }">
          <i v-if="data.icon" :class="data.icon"></i>
          {{ node.data[labelKey] }}
        </span>
      </el-tree>
    </el-option>
  </el-select>
</template>
<script>
import { SHOW_SPLIT } from "@util/variable";
import { validatenull } from "@util/validate";
function bindEvent(safe, name, event) {
  typeof safe[name] === "function" &&
    safe[name]({ value: safe.value, column: safe.column });
  safe.$emit(name, safe.value, event);
}
export default {
  name: "l-tree-select",
  props: {
    value: {},
    placeholder: String,
    idKey: {
      type: String,
      default: ""
    },
    pidKey: {
      type: String,
      default: ""
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    size: String,
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filter: {
      type: Boolean,
      default: true
    },
    defaultExpandAll: {
      type: Boolean,
      default: true
    },
    parent: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    getValue: Function,
    treeLoad: Function,
    nodeClick: Function,
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: Boolean
  },
  watch: {
    value: {
      handler() {
        this.initValue();
      },
      immediate: true
    },
    options() {
      this.$nextTick(() => {
        this.setLabel();
      });
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      value2: "",
      labelText: [""],
      filterText: "",
      defaultExpandedKeys: []
    };
  },
  mounted() {},
  computed: {
    labelShow() {
      return (this.labelText || []).join(SHOW_SPLIT).toString();
    },
    treeProps() {
      return {
        label: this.labelKey,
        children: "children",
        disabled: "disabled",
        isLeaf: "isLeaf"
      };
    }
  },
  methods: {
    initValue() {
      this.value2 = this.value;
      this.$nextTick(() => {
        this.setLabel();

        if (!this.defaultExpandAll) {
          const allNodes = this.$refs.tree.store._getAllNodes();
          allNodes &&
            allNodes.forEach((node) => {
              node.expanded = false;
            });
          this.defaultExpandedKeys = [this.value2];

          this.$refs.tree.setCurrentKey(this.value2);
        }
      });
    },
    handleClear() {
      this.value2 = undefined;
      this.labelText = [];
      this.$emit("input", "");
      this.$emit("clear");
      this.$emit("change", undefined);
    },
    setLabel() {
      if (validatenull(this.value2)) {
        this.labelText = [];
      } else if (this.options && this.$refs.tree) {
        const node = this.$refs.tree.getNode(this.value2);
        if (node) {
          this.labelText = [node.data[this.labelKey]];
        } else if (typeof this.getValue == "function") {
          this.getValue(this.value2, (v) => {
            this.labelText = v;
          });
        } else {
          this.labelText = [];
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleTreeLoad(node, resolve) {
      let callback = (list) => {
        resolve(list);
      };
      this.treeLoad && this.treeLoad(node, callback);
    },
    handleNodeClick(data) {
      if (data.disabled) return;
      if (typeof this.nodeClick === "function") this.nodeClick(data);
      if (this.$validatenull(data["children"]) || this.parent) {
        const value = data[this.valueKey];
        //const label = data[this.labelKey];
        const result = value; //this.multiple ? value.join(",") : value;
        this.value2 = value;
        this.node = [data];
        this.$refs.main.blur();

        this.$emit("input", result);
        this.$emit("change", data);
      }
    },
    handleNodeCheck() {},
    // 初始化滚动条
    handleClick(event) {
      bindEvent(this, "click", event);
    },
    initScroll(event) {
      setTimeout(() => {
        this.$nextTick(() => {
          let scrollBar = document.querySelectorAll(
            ".el-scrollbar .el-select-dropdown__wrap"
          );
          scrollBar.forEach((ele) => {
            ele.scrollTop = 0;
          });
        });
      }, 0);
      this.handleClick(event);
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
