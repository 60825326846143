<template>
  <el-date-picker
    ref="main"
    class="l-time"
    :format="myformat"
    :valueFormat="myValueformat"
    :placeholder="placeholder"
    :startPlaceholder="startPlaceholder"
    :endPlaceholder="endPlaceholder"
    :readonly="readonly"
    :disabled="disabled"
    :editable="editable"
    :clearable="clearable"
    :size="size"
    :type="getDateType()"
    :align="align"
    :rangeSeparator="rangeSeparator"
    :defaultValue="defaultValue"
    :unlink-panels="unlinkPanels"
    v-model="value2"
  >
  </el-date-picker>
</template>
<script>
export default {
  name: "l-date",
  props: {
    value: {},
    placeholder: String,
    startPlaceholder: String,
    endPlaceholder: String,
    readonly: Boolean,
    disabled: Boolean,
    editable: Boolean,

    clearable: {
      type: Boolean,
      default: true
    },
    size: String,
    dateType: {
      type: String,
      default: "date"
    },
    align: String,
    rangeSeparator: {
      type: String,
      default: "-"
    },
    defaultValue: Date,

    format: String,
    valueFormat: String,

    selectableRange: [String, Array],

    autoFormat: {
      type: Boolean,
      default: true
    },

    unlinkPanels: {
      type: Boolean,
      default: true
    }
  },
  created() {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    value2: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      }
    },
    myformat() {
      const format = this.getFormat();
      return this.format || (this.autoFormat && format);
    },
    myValueformat() {
      const format = this.getFormat();
      return this.valueFormat || (this.autoFormat && format);
    },
    isRange() {
      return ["datetimerange", "daterange", "monthrange"].includes(
        this.dateType
      );
    }
  },
  methods: {
    getDateType() {
      if (this.dateType === "datehourmin") {
        return "datetime";
      } else {
        return this.dateType;
      }
    },
    getFormat() {
      let format = "";
      switch (this.dateType) {
        case "datehourmin":
          format = "yyyy-MM-dd HH:mm";
          break;
        case "datetime":
        case "datetimerange":
          format = "yyyy-MM-dd HH:mm:ss";
          break;
        case "date":
        case "daterange":
          format = "yyyy-MM-dd";
          break;
        case "year":
          format = "yyyy";
          break;
        case "month":
        case "monthrange":
          format = "yyyy-MM";
          break;
        case "week":
          format = "yyyy第W周";
          break;
      }
      return format;
    }
  }
};
</script>
