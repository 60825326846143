(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ELEMENT"));
	else if(typeof define === 'function' && define.amd)
		define(["ELEMENT"], factory);
	else if(typeof exports === 'object')
		exports["learunui"] = factory(require("ELEMENT"));
	else
		root["learunui"] = factory(root["ELEMENT"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5f72__) {
return 