import './styles/index.less'
import AsyncComputed from 'vue-async-computed'
import Draggable from 'vuedraggable'

const myComponents = {}
const requireComponent = require.context('./', true, /index\.js$/)
requireComponent.keys().map(fileName => {
  if(requireComponent(fileName).default){
    myComponents[fileName.split('/')[1]] = requireComponent(fileName).default
  }
})

const components = {
  AsyncComputed,
  Draggable,
  ...myComponents
}



import { validatenull,
  vaildData,
  validateURL,
  isEmail,
  isMobile,
  isPhone,
  isURL,
  validateLowerCase,
  validateUpperCase,
  validatAlphabets,
  vaildatePc,
  validateEmail,
  cardid,
  isvalidatemobile,
  validatename,
  validatenum,
  validatenumord
} from '@util/validate.js';

import { getObjType,deepClone,extend,uuid,compare,randomLenNum,downFile,awaitWraper,awaitWraperEx,formValidateWraper,formClear,setFormData,getFormData,getFunction} from '@util/util.js';
import { calcDate,parseDate,formatDate,getDayText,getDayTime} from '@util/date.js';
import { getFixed,getPx} from '@util/number.js';
import { toTree} from '@util/tree.js';
import { loadStyle,openWindow,getElemWidth,getElemHeight} from '@util/dom.js';

import { t } from '@util/translate.js';
import { pagination,unique} from '@util/array.js'

import { upFirst,lowerFirst} from '@util/str.js'
import { setStore,getStore,removeStore,getAllStore,clearStore} from '@util/store.js'

import config from '../package.json'

const prototypes = {
  validatenull,
  vaildData,
  validateURL,
  isEmail,
  isMobile,
  isPhone,
  isURL,
  validateLowerCase,
  validateUpperCase,
  validatAlphabets,
  vaildatePc,
  validateEmail,
  cardid,
  isvalidatemobile,
  validatename,
  validatenum,
  validatenumord,
  randomLenNum,

  getObjType,
  deepClone,
  extend,
  uuid,
  compare,
  calcDate,
  parseDate,
  formatDate,
  getDayText,
  getDayTime,

  getFixed,
  getPx,
  toTree,

  loadStyle,
  openWindow,

  pagination,
  unique,
  t,

  upFirst,
  lowerFirst,

  getElemWidth,
  getElemHeight,
  downFile,
  awaitWraper,
  awaitWraperEx,
  formValidateWraper,
  formClear,
  setFormData,
  getFormData,
  getFunction,

  setStore,
  getStore,
  removeStore,
  getAllStore,
  clearStore
}



const install = function (Vue) {
  if (install.installed) {
    return
  }

  Object.values(components).map(component => {
    Vue.use(component)
  })

  Object.keys(prototypes).forEach((key) => {
    Vue.prototype['$' + key] = prototypes[key];
    if (typeof window !== 'undefined') {
      window['$' + key] = prototypes[key];
    }
  });

}

//  全局引用可自动安装
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
  window.learun_ui ={
    name:config.name,
    version:config.version,
    company:config.company
  }
}

export default {
  install,
  ...components
}

export {
  components
}
