<template>
  <el-select
    ref="main"
    class="l-select"
    :size="size"
    v-model="value2"
    :multiple="multiple"
    :value-key="valueKey"
    :disabled="disabled"
    :placeholder="placeholder"
    :remote="remote"
    :remoteMethod="RemoteMethod"
    :filterable="filterable"
    :filterAsValue="filterAsValue"
    :inputAsValue="inputAsValue"
    :collapseTags="collapseTags"
    :filterMethod="filterMethod"
    :clearable="clearable"
    @visible-change="handleVisibleChange"
    @change="handleChange"
    @input-change="handleInputChange"
    @clear="handleClear"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :disabled="item[disabledKey]"
    >
    </el-option>
  </el-select>
</template>
<script>
import { validatenull } from "@util/validate";
export default {
  name: "l-select",
  props: {
    value: {},
    placeholder: {
      type: String,
      default: ""
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    disabledKey: {
      type: String,
      default: "disabled"
    },
    filterAsValue: {
      type: Boolean,
      default: false
    },
    inputAsValue: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Boolean,
      default: false
    },
    remoteMethod: Function,
    size: String,
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    collapseTags: {
      type: Boolean
    },
    filterMethod: Function,
    multiple: Boolean,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  created() {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    value2: {
      get() {
        if (this.multiple) {
          if (!validatenull(this.value)) {
            let newValue = (this.value + "").split(",");
            newValue = newValue.map((value) => eval(value));
            return newValue;
          } else {
            return [];
          }
        } else {
          return this.value;
        }
      },
      set(val) {
        if (this.multiple) {
          let newValue = val.map((value) =>
            typeof value === "string" ? `"${value}"` : value
          );
          newValue = newValue.join(",");
          val = newValue;
        }
        this.value = val;
        this.$emit("input", val);
      }
    },
    RemoteMethod() {
      const remoteMethod = this.remoteMethod;
      return remoteMethod;
    }
  },
  methods: {
    handleVisibleChange(val) {
      this.$emit("visibleChange", val);
      if (val) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
    handleInputChange(val) {
      this.$emit("inputChange", val);
    },
    handleChange(val) {
      if (this.multiple) {
        if (val.length == 0) {
          this.$emit("change", undefined);
          return;
        }

        const objs = [];
        val.forEach((item) => {
          const objone = this.options.find((t) => t[this.valueKey] == item);
          objs.push(objone);
        });
        this.$emit("change", objs);
      } else {
        if (val === "") {
          this.$emit("change", undefined);
          return;
        }
        const obj = this.options.find((t) => t[this.valueKey] == val);
        this.$emit("change", obj);
      }
    },
    handleClear() {
      this.$emit("clear");
    },
    getOptions() {
      return this.options;
    },
    getSelect() {
      return this.$refs.main;
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
