<template>
  <el-dialog
    v-if="isShow && !pure"
    ref="dialog"
    :fullscreen="isfullscreen"
    :visible.sync="dialogVisible"
    :custom-class="myClass"
    :modal="modal"
    :append-to-body="appendToBody"
    :close-on-click-modal="closeOnClickModal"
    :title="title"
    :before-close="beforeClose"
    :destroy-on-close="destroyOnClose"
    :width="`${latestWidth || numberWidth}px`"
    :style="{
      'padding-left': `${left + move.left}px`,
      'padding-top': `${top + move.top}px`,
      'pointer-events': modal ? '' : 'none'
    }"
    :class="getClass()"
    :show-close="false"
    @open="openDialog"
    @opened="openedDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <template #title>
      <div class="l-dialog-header" @mousedown="onMousedown">
        <slot name="title">
          <div
            v-if="iconUrl"
            class="el-dialog__icon"
            :class="{ [iconClass]: iconClass }"
          >
            <img :src="iconUrl" class="el-dialog__icon__img" />
          </div>
          <div
            class="el-dialog__title"
            :title="title"
            :style="{
              cursor: !autoHeight && !isfullscreen && moveable ? 'move' : ''
            }"
          >
            <span class="el-dialog__title__text">{{ title }}</span>
          </div>
        </slot>
        <div class="l-dialog-header-icons">
          <button
            v-if="showMinBtn"
            @click="handleMin"
            type="button"
            class="el-dialog__headerbtn"
          >
            <i
              :class="[
                'el-dialog__close',
                { 'el-icon-bottom-right': isMin },
                { 'el-icon-bottom-right': !isMin }
              ]"
            ></i>
          </button>
          <button
            v-if="showFullscreenBtn"
            @click="handleIsFull"
            type="button"
            class="el-dialog__headerbtn"
            style="transform: rotateZ(180deg)"
          >
            <i
              :class="[
                'el-dialog__close',
                { 'el-icon-copy-document': isfullscreen },
                { 'el-icon-full-screen': !isfullscreen }
              ]"
            ></i>
          </button>
          <button
            v-if="showClose"
            @click="handleClose"
            type="button"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
      </div>
    </template>
    <div
      v-show="!isMin"
      v-loading="loading"
      :element-loading-text="$t(loadingText)"
      :class="[
        'l-dialog-window',
        { 'l-dialog-window-hasBtns': hasBtns },
        { 'l-dialog-window-hasSteps': isStep }
      ]"
      :style="{
        height: autoHeight
          ? 'auto'
          : (latestHeight > 0 ? latestHeight : heightComputed) + 'px'
      }"
    >
      <el-steps
        v-if="isStep"
        :active="stepActive"
        simple
        finish-status="success"
      >
        <el-step
          v-for="(item, index) in steps"
          :key="index"
          :title="$t(item)"
        ></el-step>
      </el-steps>

      <transition v-if="transition" name="l-dialog-transition" mode="out-in">
        <template v-if="isShowBody">
          <div class="l-dialog__transition">
            <slot></slot>
          </div>
        </template>
      </transition>
      <template v-else-if="!transition && isShowBody">
        <slot></slot>
      </template>

      <div class="l-dialog-btns" v-if="hasBtns">
        <div
          class="l-dialog-btns__extends-btns"
          :class="{ ['--' + extendsBtnsPosition]: !!extendsBtnsPosition }"
        >
          <slot name="beforeExtendsBtns"></slot>
          <template v-for="btn in extendsBtns">
            <el-upload
              v-if="showBtn(btn) && btn.component === 'el-upload'"
              :key="btn.id"
              class="el-upload"
              action="#"
              :limit="1"
              :show-file-list="false"
              :http-request="(data) => handleClickExtendsBtns(btn, data)"
            >
              <el-button
                :type="btn.type ? btn.type : 'primary'"
                size="mini"
                :key="btn.id"
                :icon="btn.icon"
                :plain="btn.plain != null ? btn.plain : true"
              >
                {{ $t(btn.label) }}
              </el-button>
            </el-upload>
            <el-button
              v-if="showBtn(btn) && btn.component !== 'el-upload'"
              :key="btn.id"
              :type="btn.type ? btn.type : 'primary'"
              size="mini"
              :icon="btn.icon"
              :plain="btn.plain != null ? btn.plain : true"
              @click="(e) => handleClickExtendsBtns(btn, e)"
            >
              {{ $t(btn.label) }}
            </el-button>
          </template>
          <slot name="afterExtendsBtns"></slot>
        </div>
        <div class="l-dialog-btns__btns">
          <!-- <slot name="leftBtns" class="l-dialog-btns__left-btns">
            <el-button
              style="float: left"
              @click="prev"
              :plain="theme == 'dark'"
              size="mini"
              :disabled="stepPrevBtn"
              >{{ $t("上一步") }}</el-button
            >
          </slot> -->
          <slot name="beforeBtns"></slot>
          <slot name="btns">
            <el-button
              v-if="isStep"
              @click="prev"
              :plain="theme == 'dark'"
              size="mini"
              :disabled="stepPrevBtn"
              >{{ $t("上一步") }}</el-button
            >
            <el-button
              v-if="isStep"
              @click="next"
              :plain="theme == 'dark'"
              size="mini"
              :disabled="stepNextBtn"
              :loading="nextBtnLoading"
              >{{ $t("下一步") }}</el-button
            >
            <el-button
              v-if="!isStep && showClose"
              :plain="theme == 'dark'"
              size="mini"
              @click="handleClose"
              >{{ closeText || $t("取消") }}</el-button
            >
            <slot name="innerBtns"></slot>
            <el-button
              v-if="showOk"
              :plain="theme == 'dark'"
              size="mini"
              type="primary"
              @click="handleOk"
              :disabled="stepSaveBtn && isStep"
              >{{ okText || (isStep ? $t("完成") : $t("确定")) }}</el-button
            >
          </slot>
          <slot name="afterBtns"></slot>
        </div>
      </div>
    </div>
  </el-dialog>
  <div class="l-dialog--pure" v-else-if="pure">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "l-dialog",
  props: {
    title: String,
    keepAlive: {
      type: Boolean,
      default: true
    },
    pure: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    autoLoading: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean
    },
    moveable: {
      type: Boolean,
      default: true
    },
    transition: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: 600
    },
    height: {
      type: Number,
      default: 400
    },
    minWidth: {
      type: Number,
      default: 300
    },
    minHeight: {
      type: Number,
      default: 200
    },
    modal: {
      type: Boolean,
      default: true
    },
    modalOnMin: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    beforeClose: Function,
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    hasBtns: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    showOk: {
      type: Boolean,
      default: true
    },
    closeText: String,
    okText: String,
    showMinBtn: {
      type: Boolean,
      default: true
    },
    showFullscreenBtn: {
      type: Boolean,
      default: true
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    isStep: {
      type: Boolean,
      default: false
    },
    moveWhenMin: {
      type: Boolean,
      default: false
    },
    stepActive: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    },
    validateSteps: Function,
    theme: {
      type: String,
      default: "default" // dark
    },
    placement: {
      type: String,
      default: "center" // 'top' 'top-left' 'top-right' 'left' 'center' 'right' 'bottom-left' 'bottom' 'bottom-right'
    },
    minPlacement: {
      type: String,
      default: "bottom-right"
    },
    zoomControls: {
      type: Boolean,
      default: true
    },
    extendsBtns: {
      type: Array,
      default: () => []
    },
    extendsBtnsPosition: {
      type: String
    },
    icon: {
      type: String
    },
    iconUrl: {
      type: String
    },
    iconClass: {
      type: String
    }
  },
  watch: {
    visible: {
      handler(n) {
        this.flag = 1;
        this.flag = 0;
        if (this.keepAlive) {
          this.dialogVisible = n;
          return;
        }
        this.isShow = n;
        if (n) {
          this.$nextTick(() => {
            this.dialogVisible = true;
          });
        }
      }
    },
    fullscreen: {
      handler(n) {
        this.isfullscreen = n;
      },
      immediate: true
    },
    isfullscreen: {
      handler(n) {
        if (n === true) {
          this.moveTop = this.move.top;
          this.moveLeft = this.move.left;
          this.move.top = 0;
          this.move.left = 0;
          if (this.latestHeight) {
            this._height = this.latestHeight;
            this.latestHeight =
              document.body.clientHeight - this.elDialogHeaderH;
          }
          this.isMin = false;
        } else {
          this.move.top = this.moveTop;
          this.move.left = this.moveLeft;
          this.latestHeight = this._height;
        }
      }
    },
    isMin: {
      handler(n) {
        if (n === true) {
          this.isfullscreen = false;
        }
      }
    }
  },
  provide() {
    const me = this;
    return {
      showBody: this.isShowBody,
      getDialog() {
        return me;
      }
    };
  },
  data() {
    return {
      isShowBody: true,
      isfullscreen: false,
      // loading: false,
      loadingText: "",
      isShow: true,
      dialogVisible: false,
      nextBtnLoading: false,

      stepPrevBtn: true,
      stepNextBtn: false,
      stepSaveBtn: true,

      move: {
        isMove: false,
        pageX: 0,
        pageY: 0,

        left: 0,
        top: 0,

        _left: 0,
        _top: 0
      },
      moveTop: 0,
      moveLeft: 0,
      isMin: false,

      flag: 0,
      elDialogDom: "",
      elDialogHeaderH: "",
      latestWidth: 0,
      latestHeight: 0,
      _height: 0
    };
  },
  // mounted() {
  //   // this.isfullscreen = this.fullscreen;
  // },
  computed: {
    heightComputed() {
      if (this.isfullscreen) {
        return document.body.clientHeight - this.elDialogHeaderH;
      }
      let height = this.height;
      if (typeof height === "string") {
        height = height.endsWith("%")
          ? (parseFloat(height) / 100) * document.body.clientHeight
          : parseFloat(height);
      } else {
        // height = height;
      }
      let _height = height + this.flag;
      if (_height + 40 > document.body.clientHeight) {
        _height = document.body.clientHeight - 32 - 40;
      }
      return _height;
    },
    left() {
      let _left = 0 + this.flag;
      if (!this.isfullscreen) {
        if (["top", "center", "bottom"].includes(this.placement)) {
          _left = (document.body.clientWidth - this.numberWidth) / 2;
        } else if (
          ["top-left", "left", "bottom-left"].includes(this.placement)
        ) {
          _left = 16;
        } else if (
          ["top-right", "right", "bottom-right"].includes(this.placement)
        ) {
          _left = document.body.clientWidth - this.numberWidth - 16;
        }
      }

      //_left += this.move.left
      return _left;
    },
    top() {
      let _top = 0 + this.flag;
      if (!this.isfullscreen) {
        if (["top", "top-left", "top-right"].includes(this.placement)) {
          _top = 16;
        } else if (["left", "center", "right"].includes(this.placement)) {
          _top = (document.body.clientHeight - this.heightComputed - 40) / 2;
        } else if (
          ["bottom-left", "bottom", "bottom-right"].includes(this.placement)
        ) {
          _top = document.body.clientHeight - this.heightComputed - 40 - 16;
        }
      }

      //_top += this.move.top

      return _top;
    },
    numberWidth() {
      let _width = 0 + this.flag;
      if (typeof this.width == "string") {
        if (this.width.indexOf("px") > -1) {
          _width = Number(this.width.replace("px", ""));
        } else {
          _width =
            (Number(this.width.replace("%", "")) * document.body.clientWidth) /
            100;
        }
      } else {
        _width = this.width;
      }

      if (_width > document.body.clientWidth) {
        _width = document.body.clientWidth - 32;
      }

      return _width;
    },
    /*myWidth(){
      if(typeof this.width == 'string'){
        return this.width
      }
      else{
        return `${this.width}px`
      }
    },*/
    myStepActive: {
      get() {
        return this.stepActive;
      },
      set(val) {
        this.$emit("update:stepActive", val);
      }
    },
    myClass() {
      let _class = "";

      if (this.theme == "dark") {
        _class = "l-dialog-dark";
      } else {
        _class = "l-dialog";
      }
      if (this.isMin) {
        if (this.moveWhenMin) {
          _class += ` l-dialog-min --pin`;
        } else {
          _class += ` l-dialog-min l-dialog-${this.minPlacement}`;
        }
      } else {
        if (this.autoHeight) {
          _class += ` l-dialog-${this.placement}`;
        }
      }
      if (this.showTitle === false) {
        _class += " --no-title";
      }
      return _class;
    }
  },
  methods: {
    getClass() {
      return {
        "learun-dialog-top": !this.modal,
        "l-dialog--hide": this.hide,
        "--min": this.isMin
      };
    },
    showBtn(btn) {
      try {
        if (btn) {
          const btnShow = btn.show;
          const btnHide = btn.hide;
          if (btnShow != null) {
            if (btnShow.call) {
              return !!btnShow(btn);
            } else {
              return !!btnShow;
            }
          } else if (btnHide != null) {
            if (btnHide.call) {
              return !btnHide(btn);
            } else {
              return !btnHide;
            }
          }
        }
      } catch {}
      return true;
    },
    rerender() {
      const me = this;
      me.isShowBody = false;
      me.$nextTick(() => {
        me.isShowBody = true;
        setTimeout(() => {
          me.openedDialog();
        });
      });
    },
    handleClose() {
      const cb = () => {
        this.$emit("cancel");
        if (!this.keepAlive) {
          this.$emit("close");
        }
        this.$emit("update:visible", false);
        if (!this.keepAlive) {
          this.$emit("closed");
        }
      };

      if (this.beforeClose) {
        this.beforeClose(cb);
      } else {
        cb();
      }
    },
    async handleOk() {
      if (this.autoLoading) {
        this.loading = true;
        if (this.$emited) {
          await this.$emited("ok", this.showLoading, this.hideLoading);
        } else {
          this.$emit("ok", this.showLoading, this.hideLoading);
        }
        this.loading = false;
      } else {
        this.$emit("ok", this.showLoading, this.hideLoading);
      }
    },
    handleMin() {
      this.isMin = !this.isMin;
      if (this.modal === true && this.modalOnMin === false) {
        const vmodalElement = document.querySelector(".v-modal");
        if (vmodalElement) {
          if (this.isMin) {
            vmodalElement.style.display = "none";
          } else {
            vmodalElement.style.display = "";
          }
        }
      }
    },
    handleIsFull() {
      this.isfullscreen = !this.isfullscreen;
    },

    closeDialog() {
      if (!this.keepAlive) {
        this.isMin = false;
      }
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    closedDialog() {
      if (!this.keepAlive) {
        this.move.left = 0;
        this.move.top = 0;
        this.latestHeight = 0;
        this.latestWidth = 0;
        this.isfullscreen = false;
      }
      this.isMin = false;
      if (this.isStep) {
        this.myStepActive = 0;
        this.$nextTick(() => {
          this.updateBtnState();
        });
      }
      this.$emit("closed");
    },
    openDialog() {
      this.$emit("open");
    },
    openedDialog() {
      this.$emit("opened", this.showLoading, this.hideLoading);
      if (this.zoomControls) {
        this.elDialogDom = this.$refs.dialog.$el.querySelector(".el-dialog");
        this.elDialogHeaderH =
          this.$refs.dialog.$el.querySelector(
            ".el-dialog__header"
          ).offsetHeight;
        this.elDialogDom.onmousemove = this.elDialogOnMousemove;
      }
    },
    showLoading(text) {
      this.loadingText = text || "保存数据中...";
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },

    // 带步骤的表单
    prev() {
      if (this.myStepActive > 0) {
        this.myStepActive--;
      }
      this.$nextTick(() => {
        this.updateBtnState();
      });
    },
    async next() {
      if (await this.myValidateSteps()) {
        if (this.myStepActive < this.steps.length - 1) {
          this.myStepActive++;
        }
        this.$nextTick(() => {
          this.updateBtnState();
        });
      }
    },
    updateBtnState() {
      this.stepSaveBtn = true;
      this.stepNextBtn = true;
      this.stepPrevBtn = true;
      switch (this.myStepActive) {
        case 0:
          this.stepNextBtn = false;
          break;
        case this.steps.length - 1:
          this.stepPrevBtn = false;
          this.stepSaveBtn = false;
          break;
        default:
          this.stepPrevBtn = false;
          this.stepNextBtn = false;
          break;
      }
    },
    async myValidateSteps() {
      this.nextBtnLoading = true;
      if (this.validateSteps) {
        const res = await this.validateSteps(this.stepActive);
        this.nextBtnLoading = false;
        return res;
      }
      this.nextBtnLoading = false;
      return true;
    },

    onMousedown: function (e) {
      if (
        this.autoHeight ||
        // this.isMin ||
        this.isfullscreen ||
        !this.moveable
      ) {
        e.preventDefault();
        return;
      }

      this.move.pageX = e.pageX;
      this.move.pageY = e.pageY;

      this.move._left = this.move.left;
      this.move._top = this.move.top;
      this.move.isMove = true;

      document.onmouseup = this.onMouseup;
      document.onmousemove = this.onMousemove;
    },

    onMousemove: function (e) {
      if (this.move.isMove) {
        const x = e.pageX - this.move.pageX;
        const y = e.pageY - this.move.pageY;

        let _left = this.move._left + x;
        let _top = this.move._top + y;

        if (_left + this.left < 0) {
          _left = -this.left;
        }
        const latestWidth = this.isMin
          ? this.$refs.dialog.$refs.dialog.clientWidth
          : this.latestWidth;
        if (latestWidth) {
          if (_left + latestWidth + this.left > document.body.clientWidth) {
            _left = document.body.clientWidth - latestWidth - this.left;
          }
        } else {
          if (
            _left + this.numberWidth + this.left >
            document.body.clientWidth
          ) {
            _left = document.body.clientWidth - this.numberWidth - this.left;
          }
        }

        const latestHeight = this.isMin
          ? this.$refs.dialog.$refs.dialog.clientHeight
          : this.latestHeight;
        const remainHeight = this.isMin ? 0 : 40;
        if (_top + this.top < 0) {
          _top = -this.top;
        }
        if (latestHeight) {
          if (
            _top + latestHeight + this.top + remainHeight >
            document.body.clientHeight
          ) {
            _top =
              document.body.clientHeight -
              latestHeight -
              this.top -
              remainHeight;
          }
        } else {
          if (
            _top + this.heightComputed + this.top + remainHeight >
            document.body.clientHeight
          ) {
            _top =
              document.body.clientHeight -
              this.heightComputed -
              this.top -
              remainHeight;
          }
        }

        this.move.left = _left;
        this.move.top = _top;
        if (this.isfullscreen) {
          this.isfullscreen = !this.isfullscreen;
        }
      }
    },
    onMouseup: function () {
      this.move.isMove = false;
      document.onmousemove = document.onmouseup = null;
    },
    elDialogOnMousemove: function (e) {
      let dialogOffsetLeft = this.elDialogDom.offsetLeft;
      let dialogOffsetTop = this.elDialogDom.offsetTop;
      const elW = this.elDialogDom.clientWidth;
      const elH = this.elDialogDom.clientHeight;
      const elBodyH = elH - this.elDialogHeaderH;
      const ELscrollTop = this.elDialogDom.scrollTop;

      if (
        (e.clientX > dialogOffsetLeft + elW - 10 &&
          ELscrollTop + e.clientY > dialogOffsetTop + elH - 10) ||
        (ELscrollTop + e.clientY < dialogOffsetTop + 5 &&
          dialogOffsetLeft + 10 > e.clientX)
      ) {
        this.elDialogDom.style.cursor = "se-resize";
      } else if (
        (dialogOffsetLeft + 10 > e.clientX &&
          ELscrollTop + e.clientY > dialogOffsetTop + elH - 10) ||
        (e.clientX > dialogOffsetLeft + elW - 10 &&
          ELscrollTop + e.clientY < dialogOffsetTop + 5)
      ) {
        this.elDialogDom.style.cursor = "sw-resize";
      } else if (
        e.clientX > dialogOffsetLeft + elW - 10 ||
        dialogOffsetLeft + 10 > e.clientX
      ) {
        this.elDialogDom.style.cursor = "w-resize";
      } else if (
        ELscrollTop + e.clientY > dialogOffsetTop + elH - 10 ||
        ELscrollTop + e.clientY < dialogOffsetTop + 5
      ) {
        this.elDialogDom.style.cursor = "s-resize";
      } else {
        this.elDialogDom.style.cursor = "default";
        this.elDialogDom.onmousedown = null;
        return;
      }
      this.elDialogDom.onmousedown = (e) => {
        const clientX = e.clientX;
        const clientY = e.clientY;
        const EloffsetLeft = this.move.left + this.left;
        const EloffsetTop = this.move.top + this.top;
        this.move._left = this.move.left;
        this.move._top = this.move.top;
        if (!this.latestHeight) this.latestHeight = elBodyH;
        if (!this.latestWidth) this.latestWidth = elW;
        const that = this;
        if (
          clientX > EloffsetLeft + 10 &&
          clientX < EloffsetLeft + elW - 10 &&
          clientY > EloffsetTop + 5 &&
          clientY < EloffsetTop + 47
        ) {
        } else {
          document.onmousemove = function (e) {
            e.preventDefault();
            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
              if (clientX > e.clientX) {
                that.latestWidth = elW + (clientX - e.clientX);
                that.move.left = that.move._left - (clientX - e.clientX);
              }
              if (clientX < e.clientX && that.latestWidth > that.minWidth) {
                that.latestWidth = elW - (e.clientX - clientX);
                that.move.left = that.move._left + e.clientX - clientX;
              }
            }
            if (
              clientX > EloffsetLeft + elW - 10 &&
              clientX < EloffsetLeft + elW
            ) {
              if (clientX > e.clientX && that.latestWidth > that.minWidth) {
                that.latestWidth = elW - (clientX - e.clientX);
              }
              if (
                clientX < e.clientX &&
                that.left + that.move.left + that.latestWidth <
                  document.body.clientWidth
              ) {
                that.latestWidth = elW + (e.clientX - clientX);
              }
            }
            if (clientY > EloffsetTop && clientY < EloffsetTop + 5) {
              if (clientY > e.clientY) {
                that.latestHeight = elBodyH + clientY - e.clientY;
                that.move.top = that.move._top - (clientY - e.clientY);
              }
              if (clientY < e.clientY && that.latestHeight > that.minHeight) {
                that.latestHeight = elBodyH - (e.clientY - clientY);
                that.move.top = that.move._top + e.clientY - clientY;
              }
            }
            const titleHeight = this.showTitle ? 30 : -40;
            if (
              ELscrollTop + clientY > EloffsetTop + elBodyH + titleHeight &&
              ELscrollTop + clientY < EloffsetTop + elBodyH + 48
            ) {
              if (clientY > e.clientY && that.latestHeight > that.minHeight) {
                that.latestHeight = elBodyH - (clientY - e.clientY);
              }
              if (
                clientY < e.clientY &&
                that.top + that.move.top + that.latestHeight + 48 <
                  document.body.clientHeight
              ) {
                that.latestHeight = elBodyH + e.clientY - clientY;
              }
            }
          };
          document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
      };
    },
    handleClickExtendsBtns(btn = {}) {
      this.$emit("extends-btns", btn);
      const clickEvent = (btn && btn.id && `${btn.id}`) || "";
      const newArguments = [...arguments].slice(1).concat(btn);
      if (btn.component === "el-upload") {
        this.$set(btn, "hide", true);
      }
      clickEvent && this.$emit(clickEvent, ...newArguments);
      this.$nextTick(() => {
        if (btn.component === "el-upload") {
          this.$set(btn, "hide", false);
        }
      });
    }
  }
};
</script>
<style lang="less">
@import "./index.less";

.l-dialog-transition-leave-active,
.l-dialog-transition-enter-active {
  transition: all 0.5s;
}

.l-dialog-transition-enter {
  opacity: 0;
  transform: translateY(30px);
}

.l-dialog-transition-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.l-dialog {
  &__transition {
    height: 100%;
  }

  &.--no-title {
    > .el-dialog__header {
      display: none;
    }
  }
}
</style>
