<template>
  <div class="l-input-color">
    <el-input :placeholder="placeholder"
              v-model="value2"
              :size="size"
              ref="main"
              :readonly="readonly"
              @click.native="handleClick"
              :clearable="disabled?false:clearable"
              :disabled="disabled">
      <template slot="append">
        <el-color-picker size="mini"
                         v-model="value2"
                         :disabled="disabled"
                         show-alpha
                         :predefine="predefineColors"></el-color-picker>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name:'l-input-color',
  props: {
    value:{},
    placeholder:{
      type:String,
      default:'请选择颜色'
    },
    size:String,
    disabled:Boolean,
    clearable:Boolean,
    readonly:Boolean,
  },
  data () {
    return {
        predefineColors: [
            "#ff4500",
            "#ff8c00",
            "#ffd700",
            "#90ee90",
            "#00ced1",
            "#1e90ff",
            "#c71585",
            "rgba(255, 69, 0, 0.68)",
            "rgb(255, 120, 0)",
            "hsv(51, 100, 98)",
            "hsva(120, 40, 94, 0.5)",
            "hsl(181, 100%, 37%)",
            "hsla(209, 100%, 56%, 0.73)",
            "#c7158577"
        ],
        text:''
    };
  },
  mounted () {
  },
  computed:{
    value2:{
      get(){
        return this.value || this.text;
      },
      set(val){
        this.text = val;
        this.$emit('input', val);
        this.$emit('change', val);
      }
    }
  },
  methods:{
      handleClick(event){
          this.$emit('click', this.value || this.text,event);
      }
  }
}
</script>
<style lang="less">
@import './index.less';
</style>