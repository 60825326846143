<template>
  <edit-table
    class="el-table__columns-setting"
    :columns="columns"
    :dataSource="newValue"
    height="calc(100% - 8px)"
    :isSort="true"
    :isShowNum="false"
    :hasAdd="false"
    :hasRemove="false"
    @add="handleAddRow"
    @delete="handleDeleteRow"
    @moveableChange="moveableChange"
  >
    <template v-slot:label="scope">
      <el-input
        size="mini"
        v-model="scope.row.label"
        :title="scope.row.label"
        clearable
        :placeholder="$t('请输入')"
      ></el-input>
    </template>
    <template v-slot:width="scope">
      <el-input-number
        size="mini"
        v-model="scope.row.width"
        :min="1"
        label="输入数字"
        controls-position="right"
      ></el-input-number>
    </template>
    <template v-slot:minWidth="scope">
      <el-input-number
        size="mini"
        v-model="scope.row.minWidth"
        :min="1"
        label="输入数字"
        controls-position="right"
      ></el-input-number>
    </template>

    <template v-slot:hide="scope">
      <el-switch v-model="scope.row.hide"></el-switch>
    </template>

    <template v-slot:fixed="scope">
      <l-select
        size="mini"
        :options="fixedOptions"
        v-model="scope.row.fixed"
      ></l-select>
    </template>
    <!-- <template v-slot:tooltip="scope">
        <el-switch v-model="scope.row.tooltip"></el-switch>
      </template> -->
    <!-- <template v-slot:required="scope">
        <el-switch v-model="scope.row.required"></el-switch>
      </template> -->

    <template v-slot:sortable="scope">
      <el-switch v-model="scope.row.sortable"></el-switch>
    </template>

    <!-- <el-table-column align="center" width="40" fixed="right">
      <template slot-scope="scope">
        <button
          type="button"
          @click.stop="handleInsertRow(scope.$index, scope.row)"
          class="el-button el-button--text el-button--mini"
        >
          <i class="el-icon-plus"></i>
        </button>
      </template>
    </el-table-column> -->
  </edit-table>
</template>

<script>
export default {
  name: "columns-editor",
  //   mixins: [i18n, propertyMixin],
  components: {
    EditTable: () => import("../../lrEditTable")
  },
  props: {
    value: Object
    // title: String,
    // designer: Object,
    // selectedWidget: Object,
    // optionModel: Object,
    // immediate: { type: Boolean, default: false }
  },
  //   components: {
  //     CodeEditor,
  //     CodeDialog,
  //     dataCodeList,
  //     DataCodeEditor,
  //     optionList,
  //     ArrayEditor,
  //     SwitchEditor,
  //     ServiceEditor
  //   },
  watch: {
    value: {
      handler(nv) {
        this.$emit("change", nv);
      },
      deep: true
    }
  },
  computed: {
    optionValue() {
      return "options";
    },
    apiKeyList() {
      var list = this.optionModel.dataSource;
      if (list && list.length != 0) {
        return Object.keys(list[0]).map((t) => {
          return { label: t, value: t };
        });
      } else {
        return [];
      }
    }
  },
  data() {
    const newValue = this.value.filter((item) => item.setting !== false);
    return {
      values: [],
      newValue,
      dialogVisible: false,
      copyValue: void 0,
      columns: [
        // {
        //   label: "字段名称",
        //   prop: "prop",
        //   minWidth: "200",
        //   fixed: "left",
        //   required: showRequired,
        //   align: "center"
        // },
        {
          label: "显示名称",
          prop: "label",
          required: true,
          align: "center"
        },
        { label: "列宽度", prop: "width", width: "110", align: "center" },
        // {
        //   label: "列最小宽度",
        //   prop: "minWidth",
        //   minWidth: "120",
        //   align: "center"
        // },
        { label: "固定位置", prop: "fixed", width: "110", align: "center" },
        // { label: "文本对齐", prop: "align", minWidth: "120", align: "center" },
        { label: "隐藏", prop: "hide", width: "70", align: "center" },
        // { label: "单行", prop: "tooltip", minWidth: "70", align: "center" },
        { label: "排序", prop: "sortable", width: "70", align: "center" }
        // { label: "必填", prop: "required", minWidth: "70", align: "center" },
        // { label: "导出", prop: "export", minWidth: "70", align: "center" },
        // { label: "导入", prop: "import", minWidth: "70", align: "center" },
        // {
        //   label: "导入配置",
        //   prop: "importConfig",
        //   minWidth: "80",
        //   align: "center"
        // },
        // {
        //   label: "双击编辑",
        //   prop: "dblclickEdit",
        //   minWidth: "80",
        //   align: "center"
        // },
        // {
        //   label: "数据类型",
        //   prop: "dataType",
        //   minWidth: "150",
        //   align: "center"
        // },
        // {
        //   label: "数据配置",
        //   prop: "dataCode",
        //   minWidth: "150",
        //   align: "center"
        // },
        // {
        //   label: "列class样式",
        //   prop: "columnClassName",
        //   minWidth: "120",
        //   align: "center"
        // },
        // {
        //   label: "表头class样式",
        //   prop: "headerClassName",
        //   minWidth: "120",
        //   align: "center"
        // },
        // {
        //   label: "表头style样式",
        //   prop: "headerStyle",
        //   minWidth: "120",
        //   align: "center"
        // },
        // { label: "格式化函数", prop: "formatter", width: "90", align: "center" }
        //{ label: "合并列", prop: "props", width: "120" } //下拉多选
      ],
      fixedOptions: [
        { label: "固定左侧", value: "left" },
        { label: "固定右侧", value: "right" }
      ],
      alignOptions: [
        {
          value: "left",
          label: "居左对齐"
        },
        {
          value: "center",
          label: "居中对齐"
        },
        {
          value: "right",
          label: "居右对齐"
        }
      ],
      dataTypeOptions: [
        {
          label: "自定义数据",
          value: "mydata"
        },
        {
          label: "数据字典",
          value: "dataItem"
        },
        {
          label: "数据源",
          value: "dataSource"
        },
        {
          label: "年月日时分秒",
          value: "datetime"
        },
        {
          label: "年月日时分",
          value: "datetime2"
        },
        {
          label: "年月日",
          value: "date"
        },
        {
          label: "时分秒",
          value: "time"
        },
        {
          label: "公司",
          value: "company"
        },
        {
          label: "部门",
          value: "department"
        },
        {
          label: "角色",
          value: "role"
        },
        {
          label: "岗位",
          value: "post"
        },
        {
          label: "人员",
          value: "user"
        }
      ]
    };
  },
  mounted() {
    // this.columns.forEach((v) => {
    //   v.isHidden = this.isHideColumn(v.prop) || !this.isShowColumn(v.prop);
    // });
  },
  methods: {
    moveableChange({ dataSource }) {
      const restValue = [];
      this.value.forEach((ov) => {
        if (!dataSource.find((nv) => nv === ov)) {
          restValue.push(ov);
        }
      });
      if (restValue.length) {
        this.value.splice(0);
        this.value.push(...dataSource, ...restValue);
      }
      this.$emit("moveableChange", ...arguments);
    }
  }
};
</script>

<style lang="less" scoped>
.el-table__columns-setting {
  padding: 8px 8px 0 8px;
}
</style>
