<template>
  <div class="l-panel">
    <div
      class="l-panel--warpper"
      :class="{ '--tool': $slots.toolLeft || $slots.toolRight }"
      :style="{ 'padding-top': paddingTop }"
    >
      <div v-if="title || $slots.title" class="l-panel--title">
        <slot name="title">{{ title }}</slot>
      </div>

      <div
        v-if="$slots.toolLeft || $slots.toolRight"
        class="l-panel--tool"
        :style="{ top: toolTop }"
      >
        <div class="l-panel--tool-left">
          <slot v-if="$slots.toolLeft" name="toolLeft"></slot>
          <slot v-else name="tabs"></slot>
        </div>
        <div class="l-panel--tool-right">
          <slot name="toolRight"></slot>
        </div>
      </div>
      <div
        v-if="$slots.toolLeft && $slots.tabs"
        class="l-panel--tabs"
        :style="{ top: tabsTop }"
      >
        <slot v-if="$slots.toolLeft" name="tabs"></slot>
      </div>
      <div class="l-panel--body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "l-panel",
  props: {
    title: String,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    paddingTop: function () {
      var ptop = 0;
      if (this.title || this.$slots.title) {
        ptop += 40;
      }
      if (this.$slots.toolLeft || this.$slots.toolRight) {
        ptop += 40;
      }
      if (this.$slots.tabs && this.$slots.toolLeft) {
        ptop += 40;
      }
      return ptop + "px";
    },
    toolTop: function () {
      if (this.title || this.$slots.title) {
        return "40px";
      } else {
        return "0";
      }
    },
    tabsTop() {
      var ptop = 0;
      if (this.title || this.$slots.title) {
        ptop += 40;
      }
      if (this.$slots.toolLeft) {
        ptop += 40;
      }
      return ptop + "px";
    }
  },
  methods: {}
};
</script>
<style lang="less">
@import "./index.less";
</style>
