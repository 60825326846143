<template>
  <div
    v-loading="loading"
    element-loading-text="数据加载中..."
    element-loading-spinner="el-icon-loading"
    :style="getTableDivStyle()"
    :class="[
      'l-table',
      isPage ? 'l-table--haspagination' : '',
      { '--auto-height': isAutoHeight }
    ]"
  >
    <el-table
      v-if="isRender"
      :data="tableShowData"
      :style="getTableStyle()"
      :height="tableHeight"
      :max-height="maxHeight"
      size="mini"
      :stripe="stripe"
      :border="border"
      :shadow="shadow"
      :fit="fit"
      :show-header="showHeader"
      :highlight-current-row="highlightCurrentRow"
      :current-row-key="currentRowKey"
      :row-key="rowKey"
      :row-class-name="rowClassName"
      :row-style="getRowStyle()"
      :cell-class-name="cellClassName"
      :cell-style="getCellStyle()"
      :header-row-class-name="headerRowClassName"
      :header-row-style="headerRowStyle"
      :header-cell-class-name="getHeaderCellClassName"
      :header-cell-style="getHeaderCellStyle"
      :default-expand-all="defaultExpandAll"
      :expand-row-keys="expandRowKeys"
      :default-sort="defaultSort"
      :tooltip-effect="tooltipEffect"
      :show-summary="showSummary"
      :sum-text-colspan="sumTextColspan"
      :sum-text="sumText"
      :summary-method="getSummaries"
      :span-method="spanMethod"
      :select-on-indeterminate="selectOnIndeterminate"
      :indent="indent"
      :lazy="lazy"
      :load="load"
      :tree-props="treeProps"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @selection-change="selectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @cell-click="cellClick"
      @cell-dblclick="cellDblclick"
      @row-click="onRowClick"
      @row-contextmenu="rowContextmenu"
      @row-dblclick="onRowDblclick"
      @header-click="headerClick"
      @header-contextmenu="headerContextmenu"
      @sort-change="sortableChange"
      @filter-change="filterChange"
      @current-change="currentChange"
      @header-dragend="headerDagend"
      @expand-change="expandChange"
      ref="learunTable"
    >
      <!-- 拖动排序  -->
      <el-table-column
        :class-name="
          getColumnClassNames(
            moveableColumnClassName,
            'el-table-column--moveable'
          )
        "
        align="center"
        width="45"
        v-if="IsSort && !isTree"
        :fixed="moveableFixed"
      >
        <template slot="header">
          <i class="el-icon-sort" />
        </template>
        <template>
          <span class="learun-table__drag-handler">
            <i class="el-icon-sort" />
          </span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="isMultiSelect"
        :fixed="isFixed"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--multi-select',
            'el-table-column--selection'
          )
        "
        align="center"
        type="selection"
        width="45"
        header-align="center"
      ></el-table-column>

      <el-table-column
        v-if="isSingleSelect"
        :class-name="
          getColumnClassNames(
            multiSingleClassName,
            'el-table-column--single-select'
          )
        "
        :fixed="isFixed"
        align="center"
        width="45"
        header-align="center"
      >
        <template v-slot="scope">
          <el-radio
            class="el-table-column__radio"
            :value="getRadioValue(scope.row, scope.$index)"
            @input="(val) => setRadioValue(val, scope.row, scope.$index)"
            @click.native.stop=""
            :label="getRadioLabel(scope.row, scope.$index)"
            >{{ "" }}
          </el-radio>
        </template>
      </el-table-column>

      <el-table-column
        v-if="isExpand"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--expand'
          )
        "
        :fixed="isFixed"
        type="expand"
        v-slot="scope"
        width="28"
      >
        <slot v-bind="scope" name="table_expand"></slot>
      </el-table-column>

      <el-table-column
        v-if="IsNum"
        :label="ShowNumLabel"
        align="center"
        :class-name="
          getColumnClassNames(
            multiSelectColumnClassName,
            'el-table-column--num'
          )
        "
        :fixed="getNumFixed()"
        type="index"
        :index="indexMethod"
      >
      </el-table-column>

      <template v-for="(col, index) in myColumns">
        <el-table-column
          v-if="isShowColumn(col)"
          :class-name="getColumnClassName({ column: col, columnIndex: index })"
          :key="col.prop || index"
          v-bind="col"
          :show-overflow-tooltip="col.isNotAutoWrap == true ? true : false"
          :show-overflow-header-tooltip="isHeaderTooltip(col)"
          :fixed="getColumnFixed(col)"
          :filters="getColumnFilters(col)"
          :filter-method="getColumnFilterMethod(col)"
          :sortable="col.sortable != null ? col.sortable : sortable"
          :min-width="col.minWidth"
          :header-align="col.headerAlign"
        >
          <template v-slot:header="{ column }">
            <el-tooltip
              v-if="isHeaderTooltip(col, column)"
              effect="light"
              :content="column.label"
            >
              <div class="ellipsis">
                <span v-if="isRequired(col)" style="color: red">*</span
                ><span>{{ column.label }}</span>
              </div>
            </el-tooltip>
            <template v-else>
              <span v-if="isRequired(col)" style="color: red">*</span
              ><span>{{ column.label }}</span>
            </template>
          </template>
          <template v-slot="scope">
            <el-tooltip
              effect="light"
              :content="scope.$text"
              v-if="isColumnTooltip(col, scope)"
            >
              <slot v-bind="scope" :name="col.prop">
                <div class="ellipsis">
                  {{ scope.$text }}
                </div>
              </slot>
            </el-tooltip>
            <slot v-else v-bind="scope" :name="col.prop">
              <!-- <el-tooltip
                effect="light"
                :content="
                  columnText(scope.$index, scope.row, col, scope)
                "
                placement="top"
                v-if="col.tooltip"
              >
                <div class="ellipsis">{{ scope.row[col.prop] }}</div>
              </el-tooltip> -->
              <div>
                {{ scope.$text }}
              </div>
            </slot>
          </template>
        </el-table-column>
      </template>
      <slot></slot>
    </el-table>
    <div v-if="isPage" class="l-table--pagination">
      <el-pagination
        :small="smallPage"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="pageSizes || [20, 50, 100, 200]"
        :page-size.sync="pageSize2"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
    <l-drawer
      :title="$t('表格列设置')"
      :visible.sync="columnsVisible"
      :showOk="false"
      :showClose="false"
      @close="handleCloseColumnsSetting"
      :wrapperClosable="true"
      size="600px"
    >
      <columns-setting
        v-model="columns"
        @change="handleColumnsSettingChange"
        @moveableChange="handleColumnsSettingMoveableChange"
      ></columns-setting>
    </l-drawer>
  </div>
</template>
<script>
import tableEvent from "@util/tableEvent";
//import dynamicColumn from "./dynamic-column";
import ColumnsSetting from "./columnsSetting";
export default {
  name: "l-table",
  mixins: [tableEvent()],
  components: {
    ColumnsSetting
    //dynamicColumn
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    // loading: {
    //   type: Boolean,
    //   default: false
    // },
    smallPage: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number]
    },
    tableWidth: {
      type: [String, Number],
      default: "100%"
    },
    height: {
      type: [String, Number],
      default: "100%"
    },
    maxHeight: {
      type: [String, Number]
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    stripe: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    fit: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },

    currentRowKey: [String, Number],
    // rowKey: [String, Function],

    // rowClassName: [String, Function],
    // rowStyle: [Function, Object],
    // cellClassName: [String, Function],
    // cellStyle: [Function, Object],
    // headerRowClassName: [String, Function],
    // headerCellClassName: [String, Function],
    // headerRowStyle: [String, Function],
    // headerCellStyle: [String, Function],
    tooltip: {
      type: Boolean,
      default: false
    },

    defaultExpandAll: Boolean,
    expandRowKeys: {
      type: Array
    },

    defaultSort: Object,

    tooltipEffect: String,

    showSummary: {
      type: Boolean,
      default: false
    },
    sumText: {
      type: String,
      default: "合计"
    },
    summaryMethod: Function,

    spanMethod: Function,

    selectOnIndeterminate: {
      type: Boolean,
      default: true
    },

    indent: {
      type: Number,
      default: 16
    },
    lazy: Boolean,
    load: Function,
    treeProps: Object,

    isPage: {
      type: Boolean,
      default: false
    },
    pageSizes: Array,
    pageTotal: {
      type: Number,
      default: 0
    },
    tablePage: {
      type: Number,
      default: 1
    },

    // isShowNum: {
    //   type: Boolean,
    //   default: null
    // },
    ShowNumLabel: {
      type: String,
      default: "序号"
    },

    isTree: {
      type: Boolean,
      default: false
    },
    pidKey: {
      type: String
    },
    isChild: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 20
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // selectedData: [],
      // columnsVisible: false,
      columnsChecks: null,
      // currentRow: null,
      pageSize3: 0
    };
  },
  mounted() {
    this.setSort();
    if (this.isChild) {
      const list = this.$el.querySelectorAll(".el-table__row");
      list.forEach((row) => {
        row.classList.add("el-table__row2");
        row.classList.remove("el-table__row");
      });
    }
  },
  computed: {
    isAutoHeight() {
      return this.autoHeight === true;
    },
    pageSize2: {
      get() {
        if (this.pageSize3 != 0) {
          return this.pageSize3;
        }
        return this.pageSize;
      },
      set(val) {
        this.pageSize3 = val;
        this.$emit("update:pageSize", val);
      }
    },
    radioSelected: {
      get() {
        const selectedData = this.selectedData;
        const selectedRowId =
          selectedData && selectedData[0] && selectedData[0][this.rowKey];
        return selectedRowId;
      }
    },
    tableHeight() {
      if (this.height == "notset" || this.isChild) {
        return;
      } else {
        return "100%";
      }
    },
    tableHeight2() {
      if (this.height == "notset" || this.isChild) {
        return;
      } else {
        return this.height;
      }
    },
    currentPage: {
      get() {
        if (!this.$validatenull(this.tablePage)) {
          return this.tablePage;
        } else {
          return 1;
        }
      },
      set(val) {
        this.$emit("update:tablePage", val);
      }
    },
    tableShowData() {
      if (this.isTree) {
        return this.$toTree(
          this.dataSource,
          this.multiSelectKey,
          this.pidKey,
          this.multiSelectKey,
          this.multiSelectKey
        );
      } else {
        return this.filterDataSource;
      }
    },
    // multiSelectKey() {
    //   if (this.selectKey) {
    //     return this.selectKey;
    //   } else if (typeof this.rowKey == "string") {
    //     return this.rowKey;
    //   } else {
    //     return this.selectKey;
    //   }
    // },
    // selectedValues() {
    //   return this.selectedData.map((t) => t[this.multiSelectKey]);
    // },
    defaultCheckedKeys() {
      return this.columns.filter((t) => t.isNotShow != true).map((t) => t.prop);
    },
    myColumns() {
      if (this.columns.length == 0) {
        return [{ label: "", prop: "learun_null", minWidth: "1" }];
      }
      let res;
      if (this.columnsChecks != null) {
        res = this.columns.filter(
          (t) => this.columnsChecks.indexOf(t.prop) != -1
        );
      } else {
        res = this.columns.filter(
          (t) => this.defaultCheckedKeys.indexOf(t.prop) != -1
        );
      }

      if (res.length == 0) {
        return [{ label: "", prop: "learun_null", minWidth: "1" }];
      }
      return res;
    }
  },
  watch: {
    dataSource() {
      if (this.isChild) {
        const list = this.$el.querySelectorAll(".el-table__row");
        list.forEach((row) => {
          row.classList.add("el-table__row2");
          row.classList.remove("el-table__row");
        });
      }
      this.selectRows();
    }
  },
  methods: {
    onClick() {},
    getCellStyle() {
      const cellStyle = Object.assign({ padding: "2px 0" }, this.cellStyle);
      return cellStyle;
    },
    getTableDivStyle() {
      return {
        width: this.width,
        height: this.tableHeight2
      };
    },
    getTableStyle() {
      return {
        width: this.tableWidth
      };
    },
    isShowColumn(item) {
      const isShowColumn = item.hide !== true;
      if (isShowColumn && this.lr_hasPageColumn) {
        return this.lr_hasPageColumn(item.prop);
      }
      return isShowColumn;
    },
    indexMethod(index) {
      if (this.isPage) {
        return (this.currentPage - 1) * this.pageSize2 + index + 1;
      } else {
        return index + 1;
      }
    },
    handleSizeChange(val) {
      this.pageSize2 = val;
      this.$emit("loadPageData", { rows: val, page: this.currentPage });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("loadPageData", { rows: this.pageSize2, page: val });
    },
    doLayout() {
      this.$nextTick(() => {
        this.$refs.learunTable.doLayout();
      });
    },

    // 关于多选
    // reset() {
    //   this.selectedData = [];
    //   this.$refs.learunTable.clearSelection();
    // },
    // getSelected() {
    //   return this.$deepClone(this.selectedData);
    // },
    // getSelectedData() {
    //   return this.selectedData.map(
    //     (t) =>
    //       this.dataSource.find(
    //         (tt) => t[this.multiSelectKey] === tt[this.multiSelectKey]
    //       ) || t
    //   );
    // },
    // handleSelect(selection, row) {
    //   if (!this.reserveSelection) {
    //     this.$emit("select", selection, row);
    //     return;
    //   }
    //   // 获取增加项
    //   const addList = selection.filter(
    //     (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) == -1
    //   );
    //   if (addList.length > 0) {
    //     this.selectedData = addList.concat(this.selectedData);
    //   } else {
    //     // 获取当前页面没有被选中的
    //     let notSelectedList = this.dataSource.filter(
    //       (t) =>
    //         selection.findIndex(
    //           (t2) => t2[this.multiSelectKey] == t[this.multiSelectKey]
    //         ) == -1
    //     );
    //     // 获取减少项
    //     let deleteList = notSelectedList.filter(
    //       (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
    //     );
    //     this.selectedData = this.selectedData.filter(
    //       (t) =>
    //         deleteList.findIndex(
    //           (t2) => t2[this.multiSelectKey] == t[this.multiSelectKey]
    //         ) == -1
    //     );
    //   }

    //   this.$emit("select", selection, row);
    // },
    // handleSelectAll(selection) {
    //   if (this.isTree) {
    //     if (this.dataSource.length > 0) {
    //       if (
    //         this.dataSource.filter(
    //           (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
    //         ).length < this.dataSource.length
    //       ) {
    //         let needSelectData = this.dataSource.map(
    //           (t) => t[this.multiSelectKey]
    //         );
    //         this.selectTreeRows2(this.tableShowData, needSelectData);
    //         this.handleSelect(this.dataSource);
    //       } else {
    //         // 表示全部不选中
    //         this.$refs.selectTable && this.$refs.selectTable.clearSelection();
    //         this.handleSelect([]);
    //       }
    //     }
    //   } else {
    //     this.handleSelect(selection);
    //   }
    //   this.$emit("selectAll", selection);
    // },
    // selectRows() {
    //   if (!this.isMultiSelect || !this.reserveSelection) {
    //     return;
    //   }
    //   this.$nextTick(() => {
    //     if (this.isTree) {
    //       this.selectTreeRows(this.tableShowData);
    //     } else {
    //       this.dataSource.forEach((row) => {
    //         if (this.selectedValues.indexOf(row[this.multiSelectKey]) != -1) {
    //           this.$refs.learunTable &&
    //             this.$refs.learunTable.toggleRowSelection(row, true);
    //         }
    //       });
    //     }
    //   });
    // },
    // selectTreeRows(data) {
    //   data.forEach((row) => {
    //     if (this.selectedValues.indexOf(row.value) != -1) {
    //       this.$refs.learunTable &&
    //         this.$refs.learunTable.toggleRowSelection(row, true);
    //       this.$refs.selectTable &&
    //         this.$refs.selectTable.toggleRowSelection(row, true);
    //     }
    //     if (row.children) {
    //       this.selectTreeRows(row.children);
    //     }
    //   });
    // },
    // selectTreeRows2(data, selectValues) {
    //   data.forEach((row) => {
    //     if (selectValues.indexOf(row.value) != -1) {
    //       this.$refs.learunTable &&
    //         this.$refs.learunTable.toggleRowSelection(row, true);
    //       this.$refs.selectTable &&
    //         this.$refs.selectTable.toggleRowSelection(row, true);
    //     }
    //     if (row.children) {
    //       this.selectTreeRows2(row.children, selectValues);
    //     }
    //   });
    // },

    // 动态显示列
    // openColumnsSetting() {
    //   this.columnsVisible = true;
    // },
    applyColumnsSetting() {
      this.$nextTick(() => {
        this.$refs.learunTable.doLayout();
      });
    },
    handleColumnsCheck($node, data) {
      this.columnsChecks = data.checkedKeys.concat(data.halfCheckedKeys);
      this.$nextTick(() => {
        this.$refs.learunTable.doLayout();
      });
      this.$emit("handleColumnsCheck");
    },
    getColumnsCheck() {
      return this.columnsChecks;
    },
    // getCurrentRow() {
    //   return this.currentRow;
    // },
    // getCurrentRowIndex() {
    //   return this.dataSource.indexOf(this.currentRow);
    // },
    // setCurrentRow(row) {
    //   this.$refs.learunTable && this.$refs.learunTable.setCurrentRow(row);
    //   this.currentRow = row;
    // },
    setColumnsCheck(columnsChecks) {
      this.columnsChecks = columnsChecks;
    },

    // onRowClick(row) {
    //   this.setCurrentRow(row);
    //   this.rowClick(row);
    // },
    onRowDblclick(row, column, event) {
      this.setCurrentRow(row);
      this.rowDblclick(row, column, event);
    },
    // 行排序(暂时不支持树形结构)
    setSort() {
      const callback = () => {
        const el = this.$refs.learunTable.$el.querySelectorAll(
          ".el-table__body-wrapper > table > tbody"
        )[0];

        this.dragSortable = window.Sortable.create(el, {
          ghostClass: "learun-table__moveable",
          handle: ".learun-table__drag-handler",
          onEnd: (evt) => {
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            const row = this.dataSource.splice(oldIndex, 1)[0];
            this.dataSource.splice(newIndex, 0, row);
            this.$emit("moveableChange", {
              oldIndex,
              newIndex,
              row,
              dataSource: this.dataSource
            });
          }
        });
      };
      if (this.IsSort && !this.isTree) {
        this.$nextTick(() => {
          callback();
        });
      }
    },

    // columnText(index, row, column, scope) {
    //   if (typeof column.formatter == "function") {
    //     return column.formatter({
    //       row: row,
    //       column: scope.column,
    //       cellValue: row[column.prop],
    //       index: index
    //     });
    //   } else {
    //     this.loadCellData(row[column.prop], column);
    //     return this.getCellText(row[column.prop], column);
    //   }
    // },
    // loadCellData(value, { dataType, dataCode }) {
    //   if (dataType && value != null) {
    //     switch (dataType) {
    //       case "dataItem":
    //         this.lr_loadDataItem && this.lr_loadDataItem(dataCode);
    //         break;
    //       case "dataSource":
    //         this.lr_loadDataSourceData && this.lr_loadDataSourceData(dataCode);
    //         break;
    //       case "company":
    //         this.lr_loadCompanys && this.lr_loadCompanys();
    //         break;
    //       case "department":
    //         this.lr_loadDepartment && this.lr_loadDepartment(value);
    //         break;
    //       case "role":
    //         this.lr_loadRole && this.lr_loadRole(value);
    //         break;
    //       case "post":
    //         this.lr_loadPost && this.lr_loadPost(value);
    //         break;
    //       case "user":
    //         this.lr_loadUsers && this.lr_loadUsers(value);
    //         break;
    //       case "areas":
    //         if (this.lr_loadAreas) {
    //           const areaList = value.split(",");
    //           if (areaList.length == 3) {
    //             this.lr_loadAreas(0),
    //               this.lr_loadAreas(areaList[0]),
    //               this.lr_loadAreas(areaList[1]);
    //           }
    //         }
    //         break;
    //     }
    //   }
    // },
    // getCellText(
    //   value,
    //   { dataType, dataCode, options, valueKey, labelKey, format, prop }
    // ) {
    //   if (window.$validatenull(value)) {
    //     return "";
    //   }
    //   if (!this.lr_dataSourceName) {
    //     return value;
    //   }
    //   switch (dataType) {
    //     case "mydata": {
    //       const dataSource = [];
    //       this.findTreeItem(options, dataSource); //静态数据
    //       return this.lr_dataSourceName(
    //         dataSource,
    //         value,
    //         "value",
    //         "label",
    //         prop
    //       );
    //     }
    //     case "dataItem":
    //       return this.lr_dataItemName(this.lr_dataItem[dataCode], value);
    //     case "dataSource":
    //       return this.lr_dataSourceName2(dataCode, value, valueKey, labelKey);
    //     case "datetime":
    //       return window.$formatDate(value, format || "yyyy-MM-dd hh:mm:ss");
    //     case "datetime2":
    //       return window.$formatDate(value, format || "yyyy-MM-dd hh:mm");
    //     case "date":
    //       return window.$formatDate(value, format || "yyyy-MM-dd");
    //     case "time":
    //       return window.$formatDate(value, format || "hh:mm:ss");
    //     case "company":
    //       return this.lr_loadCompanyName(value);
    //     case "department":
    //       return this.lr_departmentNameByOne(value);
    //     case "role":
    //       return (this.lr_role[value] || {})[labelKey || "f_FullName"] || "";
    //     case "post":
    //       return (this.lr_post[value] || {})[labelKey || "f_FullName"] || "";
    //     case "user":
    //       return this.lr_userName(value);
    //     case "areas":
    //       return this.lr_areasName(value);
    //     default:
    //       return value;
    //   }
    // },
    // findTreeItem(arr, res) {
    //   arr.forEach((t) => {
    //     res.push({ value: t.value, label: t.label });
    //     if (t.children) {
    //       this.findTreeItem(t.children, res);
    //     }
    //   });
    // },

    getSummaries(param) {
      this.$nextTick(() => {
        this.$refs.learunTable.doLayout();
      });

      if (this.summaryMethod) {
        return this.summaryMethod(param);
      }

      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.sumText;
          return;
        }

        const myColumn = this.myColumns.find((t) => t.prop == column.property);

        if (myColumn && myColumn.isSummary) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            //sums[index] += ' 元';
          } else {
            sums[index] = "N/A";
          }
        } else {
          sums[index] = "";
        }
      });

      return sums;
    }
    // isRequired(property) {
    //   let myColumn = this.columns.find((t) => t.prop == property);
    //   if (!myColumn) {
    //     return false;
    //   }
    //   return myColumn.required;
    // }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
