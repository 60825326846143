<template>
  <l-table
    :columns="columns"
    :dataSource="dataSource"
    height="notset"
    :loading="loading"
    :isPage="isPage"
    :pageTotal="tableTotal"
    :tablePage.sync="tableCurrentPage"
    :pageSizes="[20, 30, 50]"
    :pageSize="tablePageSize"
    :default-expand-all="defaultExpandAll"
    @loadPageData="turnTablePage"
    ref="selectTable"
  >
  </l-table>
</template>
<script>
export default {
  name: "l-view-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    code: String,
    apiCode: {
      type: String,
      default: "",
    },
    apiUrl: {
      type: String,
      default: "",
    },
    apiMethod: {
      type: String,
      default: "",
    },
    apiField: {
      type: String,
      default: "",
    },
    paramFiled: [String, Number],
    isPage: {
      type: Boolean,
      default: true,
    },
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
    dataType: {
      type: [String, Number],
      default: "3",
    },
  },
  data() {
    return {
      dataSource: [],
      tableTotal: 0,
      tableCurrentPage: 1,
      tablePageSize: 20,
      loading: false,
    };
  },
  mounted() {
    this.loadTableData();
  },
  watch: {
    paramFiled() {
      this.loadTableData();
    },
  },
  methods: {
    arraytoLowerCase(data) {
      if (Array.isArray(data)) {
        return data.map((item) => {
          if (Object.prototype.toString.call(item) == "[object Object]") {
            return Object.keys(item).reduce((acc, cur) => {
              acc[cur.toLowerCase()] = item[cur];
              return acc;
            }, {});
          }
          return item;
        });
      }
      return data;
    },
    turnTablePage({ rows }) {
      this.tablePageSize = rows;
      this.loadTableData(true);
    },
    async loadTableData(isNotFirst) {
      if (!isNotFirst) {
        this.tableCurrentPage = 1;
      }
      this.viewTableData();
    },

    async viewTableData() {
      this.loading = true;

      if (this.dataType == 4) {
        const apiDbsource = window.$api.data.dbsource;
        if (this.isPage) {
          if (!this.apiUrl) {
            this.loading = true;
            return;
          }
          const pageParam = {
            rows: this.tablePageSize,
            page: this.tableCurrentPage,
          };

          const params = this.apiField
            ? {
                ...pageParam,
                [this.apiField]: this.paramFiled,
              }
            : pageParam;

          const api = await this.$awaitWraper(
            apiDbsource.apiParams(
              `${window.$apiUrl}data/datainterface/${this.apiCode}`
            )
          );
          const result = await this.$awaitWraper(
            apiDbsource.apiParams(
              api.f_Url,
              api.f_RequestMethod || "get",
              params
            )
          );
          this.dataSource = this.arraytoLowerCase(result.rows);
          this.tableTotal = result.records;
        } else {
          const params = this.apiField
            ? { [this.apiField]: this.paramFiled }
            : {};
          const result = await this.$awaitWraper(
            apiDbsource.apiParams(
              this.apiParam.url,
              this.apiParam.method,
              params
            )
          );
          this.dataSource = this.arraytoLowerCase(result);
        }
      } else {
        if (this.paramFiled && this.code) {
          const apiDbsource = window.$api.data.dbsource;
          const data = await this.$awaitWraper(
            apiDbsource.getDataListByParamter(this.code, this.paramFiled)
          );
          this.dataSource = data || [];
        } else {
          this.dataSource = [];
        }
      }
      this.loading = false;
    },
  },
};
</script>
