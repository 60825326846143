
import { validatenull } from './validate';
/**
 * 小数四舍五入
 * @param {*} val 
 * @param {*} len 
 */
export function getFixed (val = 0, len = 2) {
    return Number(val.toFixed(len));
  }
  
  
/**
 * 获取像素设置值
 * @param {*} val 
 * @param {*} defval 
 */
export const getPx = (val, defval = '') => {
    if (validatenull(val)) val = defval;
    if (validatenull(val)) return '';
    val = val + '';
    if (val.indexOf('%') === -1) {
        val = val + 'px';
    }
    return val;
}



