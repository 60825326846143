import { setStore, getStore } from "@util/store.js";

export default function () {
  return {
    props: {
      numFixed: {},
      rowClassName: [String, Function],
      rowStyle: [Function, Object],
      columnClassName: [String, Function],
      cellClassName: [String, Function],
      cellStyle: [Function, Object],
      headerRowClassName: [String, Function],
      headerRowStyle: [String, Function],
      headerClassName: [String, Function],
      headerStyle: [String, Function],
      rowHeight: {
        type: [String, Number]
      },
      isMultiSelect: Boolean,
      isSingleSelect: Boolean,
      selectKey: String,
      cacheKey: String,
      rowKey: [String, Function],
      multiSelectColumnClassName: [String, Function],
      singleSelectColumnClassName: [String, Function],
      numColumnClassName: [String, Function],
      moveableColumnClassName: [String, Function],
      expandColumnClassName: [String, Function],
      shadow: {
        type: Boolean,
        default: true
      },
      headerTooltip: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      filterable: {
        type: Boolean
      },
      reserveSelection: {
        type: Boolean,
        default: true
      },
      sumTextColspan: {
        type: Boolean,
        default: false
      },
      highlightCurrentRow: {
        type: Boolean,
        default: true
      },
      toggleHighlightCurrentRow: {
        type: Boolean,
        default: true
      },
      rowClickAsSelect: {
        type: Boolean,
        default: false
      },
      clickAsDblclick: {
        type: Boolean,
        default: false
      },
      moveableFixed: {
        type: [String, Boolean],
        default: false
      },
      sortable: {
        type: Boolean,
        default: false
      },
      isMoveable: {
        type: Boolean,
        default: false
      },
      isShowNum: {
        type: Boolean,
        default: null
      },
      isNum: {
        type: Boolean,
        default: true
      },
      // isSort: {
      //   type: Boolean,
      //   default: false
      // },
      isExpand: {
        type: Boolean,
        default: false
      },
      isCacheColumns: {
        type: Boolean,
        default: false
      },
      isUpdateRender: {
        type: Boolean,
        default: true
      },
      updateRenderTime: {
        type: Number,
        default: 0
      }
      // columnsVisible: false

      // reLayoutOnInit: {
      //   type: Boolean,
      //   default: false
      // }
    },
    computed: {
      IsFilterable() {
        return this.columns.some((column) => this.getColumnFilterable(column));
      },
      IsSort() {
        return this.isMoveable;
      },
      IsNum() {
        return this.isShowNum != null ? this.isShowNum : this.isNum;
      },
      filterDataSource() {
        return this.dataSource.filter((t) => !t || !t._hide);
      },
      multiSelectKey() {
        if (this.selectKey) {
          return this.selectKey;
        } else if (typeof this.rowKey == "string") {
          return this.rowKey;
        } else {
          return this.selectKey;
        }
      },
      selectedValues() {
        return this.selectedData.map((t) => t[this.multiSelectKey]);
      },
      isFixed() {
        return (
          this.columns &&
          this.columns.find((item) => {
            return !!item.fixed;
          }) != undefined
        );
      }
    },
    data() {
      return {
        columnsVisible: false,
        selectedData: [],
        currentRow: null,
        currentRowIndex: -1,
        isRender: true
      };
    },
    created() {
      if (this.isCacheColumns && this.cacheKey) {
        const columnsCacheKey = this.getColumnsCacheKey(this.cacheKey);
        const columnsSettingCache = this.getColumnsCache(columnsCacheKey);
        if (columnsSettingCache) {
          const columns = [...this.columns];
          const newColumns = [];
          columnsSettingCache.forEach((columnCache) => {
            if (columnCache) {
              const column = columns.find(
                (column) => columnCache.prop === column.prop
              );
              if (column) {
                newColumns.push(column);
                Object.assign(column, columnCache);
                if (columnCache.sortable != null) {
                  this.$delete(column, "sortable");
                  this.$set(column, "sortable", columnCache.sortable);
                }
                if (columnCache.hide != null) {
                  this.$delete(column, "hide");
                  this.$set(column, "hide", columnCache.hide);
                }
              }
            }
          });
          const otherColumns = columns.filter(
            (column) => !newColumns.find((newColumn) => column === newColumn)
          );
          this.columns.splice(0);
          this.columns.push(...newColumns, ...otherColumns);
        }
      }
    },
    methods: {
      getColumnFilterable(column) {
        const filterable =
          column.filterable != null ? column.filterable : this.filterable;
        return !!filterable;
      },
      getColumnFilters(column) {
        const columnFilterable = this.getColumnFilterable(column);
        const filters = (column.filters = columnFilterable
          ? column.filters != null
            ? column.filters
            : []
          : null);
        return filters;
      },
      getColumnFilterMethod(column) {
        const columnFilterable = this.getColumnFilterable(column);
        const filterMethod = columnFilterable
          ? column.filterMethod != null
            ? column.filterMethod
            : this.getDefaultColumnFilterMethod(column)
          : null;
        return filterMethod;
      },
      getDefaultColumnFilterMethod() {
        return function (value, row, column) {
          const columnProperty = column.property;
          return row[columnProperty] === value;
        };
      },
      getColumnFixed(column) {
        return column.fixed === true ||
          column.fixed === "left" ||
          column.fixed === "right"
          ? column.fixed
          : false;
      },
      getColumnClassNames(...classNames) {
        return classNames
          .filter((className) => className)
          .map((className) => {
            if (className && className.call) {
              className = className();
            }
            return className;
          })
          .join(" ");
      },
      getColumnClassName({ column, columnIndex }) {
        let columnName = column.columnClassName || this.columnClassName;
        if (columnName && columnName.call) {
          columnName = columnName({ column, columnIndex });
        }
        return columnName;
      },
      scrollTo(top) {
        if (this.$refs.learunTable) {
          this.$refs.learunTable.bodyWrapper.scrollTop = top;
        }
      },
      scrollTop() {
        this.scrollTo(0);
      },
      scrollBottom() {
        this.scrollTo(9999);
      },
      isRequired(column) {
        return column && column.required;
      },
      isHeaderTooltip(column) {
        const headerTooltip =
          column.headerTooltip != null
            ? column.headerTooltip
            : this.headerTooltip;
        return !!headerTooltip;
      },
      isColumnTooltip(column, scope) {
        if (scope.$text === void 0) {
          this.setColumnText(column, scope);
        }
        return column.tooltip != null ? column.tooltip : this.tooltip;
      },
      setColumnText(column, scope) {
        const columnText = (scope.$text = this.columnText(
          scope.$index,
          scope.row,
          column,
          scope
        ));
        if (this.IsFilterable) {
          if (!this._cleanFiltered) {
            this._cleanFiltered = true;
            this.cleanFilter();
            this.$nextTime &&
              this.$nextTime(() => {
                this._cleanFiltered = false;
              }, this.updateRenderTime);
          }
          const columnFilterable = this.getColumnFilterable(column);
          if (columnText && columnFilterable) {
            const columnProp = column.prop;
            const columnValue = scope.row[columnProp];
            const foundColumn = column.filters.find(
              (filter) => filter.value === columnValue
            );
            if (!foundColumn) {
              column.filters.push({
                text: columnText,
                value: columnValue,
                _temp: true
              });
            } else {
              foundColumn.text = columnText;
            }
          }
        }
      },
      getRadioValue(row, $index) {
        const selectedData = this.selectedData;
        const selectedRow = selectedData && selectedData[0];
        let rowId;
        const rowKey = this.rowKey;
        if (row === selectedRow) {
          if (!rowKey) {
            rowId = $index;
          } else {
            rowId = (row && row[rowKey]) || $index;
          }
        }
        return rowId;
      },
      setRadioValue(val, row) {
        const selectedData = this.selectedData;
        selectedData.splice(0);
        this.handleSelect([row], row);
        this.selectRows();
      },
      getRadioLabel(row, $index) {
        const rowId = row[this.rowKey] || $index;
        return rowId;
      },
      validate(callback) {
        let res = true;
        let msg;
        const msgs = [];
        const scopes = [];
        if (this.required) {
          msgs.push(msg);
        } else
          try {
            this.dataSource.forEach((row, index) => {
              this.columns.forEach((column) => {
                if (!column.isHidden) {
                  if (column.required && this.$validatenull(row[column.prop])) {
                    scopes.push({
                      $index: index,
                      row,
                      column
                    });
                    msgs.push(
                      this.$t(`第${index + 1}行, ${column.label}不能为空`)
                    );
                    res = false;
                  }

                  if (column.patterns && column.patterns.length > 0) {
                    column.patterns.forEach((pattern) => {
                      if (
                        pattern.reg &&
                        !this.$validatenull(row[column.prop]) &&
                        !new RegExp(
                          pattern.reg.substring(1, pattern.reg.length - 1)
                        ).test(row[column.prop])
                      ) {
                        scopes.push({
                          $index: index,
                          row,
                          column,
                          pattern
                        });
                        msgs.push(
                          this.$t(`第${index + 1}行, ${column.label}校验错误`)
                        );
                        res = false;
                      }
                    });
                  }
                }
              });
            });
          } catch (error) {}
        msg = msgs.join("\n");
        try {
          if (callback) {
            const newRes = callback && callback(res, scopes, msg);
            res = newRes != null ? newRes : res;
          }
        } catch (e) {
          msg = e.toString();
          res = false;
        } finally {
          if (!res && msg) {
            msg = msg.replace(/\n/gi, "<br>");
            this.$message({
              type: "error",
              dangerouslyUseHTMLString: true,
              message: this.$t(msg)
            });
          }
        }
        return res;
      },
      selectRows() {
        if (!this.isMultiSelect || !this.reserveSelection) {
          return;
        }
        this.$nextTick(() => {
          if (this.isTree) {
            this.selectTreeRows(this.tableShowData);
          } else {
            this.dataSource.forEach((row) => {
              this.$refs.learunTable &&
                this.$refs.learunTable.toggleRowSelection(
                  row,
                  this.selectedValues.indexOf(row[this.multiSelectKey]) != -1
                );
            });
          }
        });
      },
      selectTreeRows(data) {
        data.forEach((row) => {
          if (this.selectedValues.indexOf(row.value) != -1) {
            this.$refs.learunTable &&
              this.$refs.learunTable.toggleRowSelection(row, true);
            this.$refs.selectTable &&
              this.$refs.selectTable.toggleRowSelection(row, true);
          }
          if (row.children) {
            this.selectTreeRows(row.children);
          }
        });
      },
      selectTreeRows2(data, selectValues) {
        data.forEach((row) => {
          if (selectValues.indexOf(row.value) != -1) {
            this.$refs.learunTable &&
              this.$refs.learunTable.toggleRowSelection(row, true);
            this.$refs.selectTable &&
              this.$refs.selectTable.toggleRowSelection(row, true);
          }
          if (row.children) {
            this.selectTreeRows2(row.children, selectValues);
          }
        });
      },
      handleSelect(selection, row) {
        if (!this.reserveSelection) {
          this.$emit("select", selection, row);
          return;
        }
        // 获取增加项
        const addList = selection.filter(
          (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) == -1
        );
        if (addList.length > 0) {
          this.selectedData = addList.concat(this.selectedData);
        } else {
          // 获取当前页面没有被选中的
          let notSelectedList = this.dataSource.filter(
            (t) =>
              selection.findIndex(
                (t2) => t2[this.multiSelectKey] == t[this.multiSelectKey]
              ) == -1
          );
          // 获取减少项
          let deleteList = notSelectedList.filter(
            (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
          );
          this.selectedData = this.selectedData.filter(
            (t) =>
              deleteList.findIndex(
                (t2) => t2[this.multiSelectKey] == t[this.multiSelectKey]
              ) == -1
          );
        }

        this.$emit("select", selection, row);
      },
      handleSelectAll(selection) {
        if (this.isTree) {
          if (this.dataSource.length > 0) {
            if (
              this.dataSource.filter(
                (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
              ).length < this.dataSource.length
            ) {
              let needSelectData = this.dataSource.map(
                (t) => t[this.multiSelectKey]
              );
              this.selectTreeRows2(this.tableShowData, needSelectData);
              this.handleSelect(this.dataSource);
            } else {
              // 表示全部不选中
              this.$refs.selectTable && this.$refs.selectTable.clearSelection();
              this.handleSelect([]);
            }
          }
        } else {
          this.handleSelect(selection);
        }
        this.$emit("selectAll", selection);
      },
      getRowStyle() {
        let rowHeight = this.rowHeight;
        let rowStyle = this.rowStyle;
        if (this.rowHeight != null) {
          if (typeof rowHeight === "number") {
            rowHeight += "px";
          }
          rowStyle = Object.assign({}, rowStyle, {
            height: rowHeight
          });
        }
        return rowStyle;
      },
      reset() {
        this.selectedData = [];
        this.$refs.learunTable.clearSelection();
      },
      cleanFilter() {
        const columns = this.columns;
        this._cleanFiltered = true;
        columns.forEach((column) => {
          if (column.filters) {
            const newFilters = column.filters.filter((filter) => !filter._temp);
            column.filters.splice(0);
            column.filters.push(...newFilters);
          }
        });
      },
      getSelected() {
        return this.$deepClone(this.selectedData);
      },
      getSelectedData() {
        return this.selectedData.map(
          (t) =>
            this.dataSource.find(
              (tt) => t[this.multiSelectKey] === tt[this.multiSelectKey]
            ) || t
        );
      },
      getCurrentRow() {
        return this.currentRow;
      },
      getCurrentRowIndex() {
        return this.currentRowIndex;
      },
      setCurrentRow(row) {
        this.$refs.learunTable && this.$refs.learunTable.setCurrentRow(row);
        this.currentRow = row;
        this.currentRowIndex = this.dataSource.indexOf(this.currentRow);
      },
      setCurrentRowIndex(index) {
        let row = this.dataSource[index];
        row = row != null ? row : null;
        this.setCurrentRow(row);
      },
      clearCurrentRow() {
        this.setCurrentRow(null);
      },
      getNumFixed() {
        return this.numFixed != null ? this.numFixed : this.isFixed;
      },
      columnText(index, row, column, scope) {
        if (typeof column.formatter == "function") {
          return column.formatter({
            row: row,
            column: scope.column,
            cellValue: row[column.prop],
            index: index
          });
        } else {
          this.loadCellData(row[column.prop], column);
          return this.getCellText(row[column.prop], column);
        }
      },
      setSort() {
        const callback = () => {
          const el = this.$refs.learunTable.$el.querySelectorAll(
            ".el-table__body-wrapper > table > tbody"
          )[0];

          this.dragSortable = window.Sortable.create(el, {
            ghostClass: "learun-table__moveable",
            handle: ".learun-table__drag-handler",
            onEnd: (evt) => {
              const oldIndex = evt.oldIndex;
              const newIndex = evt.newIndex;
              const row = this.dataSource.splice(oldIndex, 1)[0];

              if (this.IsSort) {
                this.dataSource.splice(newIndex, 0, row);
                const newDataSource = [...this.dataSource];
                this.dataSource.splice(0);
                this.$nextTick(() => {
                  this.dataSource.push(...newDataSource);
                  emitChange();
                });
              } else {
                this.$nextTick(() => {
                  this.dataSource.splice(newIndex, 0, row);
                  emitChange();
                });
              }
              const emitChange = () => {
                this.$emit("moveableChange", {
                  oldIndex,
                  newIndex,
                  row,
                  dataSource: this.dataSource
                });
              };
            }
          });
        };
        if (this.IsSort && !this.isTree) {
          this.$nextTick(() => {
            callback();
          });
        }
      },

      loadCellData(value, column) {
        const { dataType, dataCode, dataService, dataSource } = column;
        if (dataType && value != null) {
          switch (dataType) {
            case "dataItem":
              this.lr_loadDataItem && this.lr_loadDataItem(dataCode);
              break;
            case "dataSource":
              if (!dataSource) {
                this.$set(column, "dataSource", []);
                this.$$loadData &&
                  this.$$loadData(dataService).then((data) => {
                    column.dataSource.push(...data);
                  });
              }
              break;
            case "company":
              this.lr_loadCompanys && this.lr_loadCompanys();
              break;
            case "department":
              this.lr_loadDepartment && this.lr_loadDepartment(value);
              break;
            case "role":
              this.lr_loadRole && this.lr_loadRole(value);
              break;
            case "post":
              this.lr_loadPost && this.lr_loadPost(value);
              break;
            case "user":
              this.lr_loadUsers && this.lr_loadUsers(value);
              break;
            case "areas":
              if (this.lr_loadAreas) {
                const areaList = value.split(",");
                if (areaList.length == 3) {
                  this.lr_loadAreas(0),
                    this.lr_loadAreas(areaList[0]),
                    this.lr_loadAreas(areaList[1]);
                }
              }
              break;
          }
        }
      },
      getCellText(
        value,
        {
          dataType,
          dataCode,
          dataSource,
          options,
          valueKey,
          labelKey,
          format,
          prop
        }
      ) {
        if (window.$validatenull(value)) {
          return "";
        }
        if (!this.lr_dataSourceName) {
          return value;
        }
        try {
          switch (dataType) {
            case "mydata": {
              const dataSource = [];
              this.findTreeItem(options, dataSource); //静态数据
              return this.lr_dataSourceName(
                dataSource,
                value,
                "value",
                "label",
                prop
              );
            }
            case "dataItem":
              return this.lr_dataItemName(this.lr_dataItem[dataCode], value);
            case "dataSource":
              return this.lr_dataSourceName(
                dataSource,
                value,
                valueKey,
                labelKey
              );
            case "datetime":
              return window.$formatDate(value, format || "yyyy-MM-dd hh:mm:ss");
            case "datetime2":
              return window.$formatDate(value, format || "yyyy-MM-dd hh:mm");
            case "date":
              return window.$formatDate(value, format || "yyyy-MM-dd");
            case "time":
              return window.$formatDate(value, format || "hh:mm:ss");
            case "company":
              return this.lr_loadCompanyName(value);
            case "department":
              return this.lr_departmentNameByOne(value);
            case "role":
              return (
                (this.lr_role[value] || {})[labelKey || "f_FullName"] || ""
              );
            case "post":
              return (
                (this.lr_post[value] || {})[labelKey || "f_FullName"] || ""
              );
            case "user":
              return this.lr_userName(value);
            case "areas":
              return this.lr_areasName(value);
            default:
              return value;
          }
        } catch {}
        return value;
      },
      findTreeItem(arr, res) {
        arr.forEach((t) => {
          res.push({ value: t.value, label: t.label });
          if (t.children) {
            this.findTreeItem(t.children, res);
          }
        });
      },
      getCellStyle() {
        const cellStyle = this.cellStyle;
        return cellStyle;
      },
      getHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
        const columnProperty = column.property;
        columnIndex =
          this.columns.findIndex((column) => column.prop === columnProperty) ||
          {};
        column = this.columns[columnIndex] || {};
        let headerCellStyle =
          (column && column.headerStyle) || this.headerStyle;
        if (headerCellStyle && headerCellStyle.call) {
          headerCellStyle = headerCellStyle({
            row,
            column,
            rowIndex,
            columnIndex
          });
        }
        const newHeaderCellStyle = Object.assign(
          { padding: "2px 0" },
          headerCellStyle
        );
        return newHeaderCellStyle;
      },
      getHeaderCellClassName({ row, column, rowIndex, columnIndex }) {
        const columnProperty = column.property;
        columnIndex =
          this.columns.findIndex((column) => column.prop === columnProperty) ||
          {};
        column = this.columns[columnIndex] || {};
        let headerCellClassName =
          (column && column.headerClassName) || this.headerClassName;
        if (headerCellClassName && headerCellClassName.call) {
          headerCellClassName = headerCellClassName({
            row,
            column,
            rowIndex,
            columnIndex
          });
        }
        return headerCellClassName;
      },
      onRowClick(row, column, event) {
        if (this.rowClickAsSelect) {
          if (this.isMultiSelect) {
            this.toggleRowSelection(row);
          }
          if (this.isSingleSelect) {
            this.setRadioValue(void 0, row, void 0);
          }
        }
        if (
          this.highlightCurrentRow &&
          this.toggleHighlightCurrentRow &&
          this.getCurrentRow() === row
        ) {
          this.setCurrentRow(null);
        } else {
          this.setCurrentRow(row);
        }
        if (this.clickAsDblclick) {
          this.onRowDblclick(row, column, event);
        }
        this.rowClick(row, column, event);
      },
      onRowDblclick() {},
      select(selection, row) {
        // 当用户手动勾选数据行的 Checkbox 时触发的事件
        this.$emit("select", selection, row);
      },
      selectAll(selection) {
        // 当用户手动勾选全选 Checkbox 时触发的事件
        this.$emit("selectAll", selection);
      },
      selectionChange(selection) {
        // 当选择项发生变化时会触发该事件
        this.$emit("selectionChange", selection);
      },
      cellMouseEnter(row, column, cell, event) {
        // 当单元格 hover 进入时会触发该事件
        this.$emit("cellMouseEnter", row, column, cell, event);
      },
      cellMouseLeave(row, column, cell, event) {
        // 当单元格 hover 退出时会触发该事件
        this.$emit("cellMouseLeave", row, column, cell, event);
      },
      cellClick(row, column, cell, event) {
        // 当某个单元格被点击时会触发该事件
        this.$emit("cellClick", row, column, cell, event);
      },
      cellDblclick(row, column, cell, event) {
        // 当某个单元格被双击击时会触发该事件
        this.$emit("cellDblclick", row, column, cell, event);
      },
      rowClick(row, column, event) {
        // 当某一行被点击时会触发该事件
        this.$emit("rowClick", row, column, event);
      },
      rowContextmenu(row, column, event) {
        // 当某一行被鼠标右键点击时会触发该事件
        this.$emit("rowContextmenu", row, column, event);
      },
      rowDblclick(row, column, event) {
        // 当某一行被双击时会触发该事件
        this.$emit("rowDblclick", row, column, event);
      },
      headerClick(column, event) {
        // 当某一列的表头被点击时会触发该事件
        this.$emit("headerClick", column, event);
      },
      headerContextmenu(column, event) {
        // 当某一列的表头被鼠标右键点击时触发该事件
        this.$emit("headerContextmenu", column, event);
      },
      sortableChange({ column, prop, order }) {
        // 当表格的排序条件发生变化的时候会触发该事件 { column, prop, order }
        this.$emit("sortableChange", { column, prop, order });
      },
      filterChange(filters) {
        // 当表格的筛选条件发生变化的时候会触发该事件，参数的值是一个对象，对象的 key 是 column 的 columnKey，对应的 value 为用户选择的筛选条件的数组。
        this.$emit("filterChange", filters);
      },
      currentChange(currentRow, oldCurrentRow) {
        // 当表格的当前行发生变化的时候会触发该事件，如果要高亮当前行，请打开表格的 highlight-current-row 属性
        this.$emit("currentChange", currentRow, oldCurrentRow);
      },
      headerDagend(newWidth, oldWidth, column, event) {
        // 当拖动表头改变了列的宽度的时候会触发该事件
        this.$emit("headerDagend", newWidth, oldWidth, column, event);
      },
      expandChange(row, expanded) {
        // 当用户对某一行展开或者关闭的时候会触发该事件（展开行时，回调的第二个参数为 expandedRows；树形表格时第二参数为 expanded）
        this.$emit("expandChange", row, expanded);
      },

      clearSelection() {
        //用于多选表格，清空用户的选择
        this.$refs.learunTable.clearSelection();
      },
      toggleRowSelection(row, selected) {
        //用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
        this.$refs.learunTable.toggleRowSelection(row, selected);
      },
      toggleAllSelection() {
        //用于多选表格，切换所有行的选中状态
        this.$refs.learunTable.toggleAllSelection();
      },
      toggleRowExpansion(row, expanded) {
        //用于可展开表格与树形表格，切换某一行的展开状态，如果使用了第二个参数，则是设置这一行展开与否（expanded 为 true 则展开）
        this.$refs.learunTable.toggleRowExpansion(row, expanded);
      },
      // setCurrentRow(row) {
      //   //用于单选表格，设定某一行为选中行，如果调用时不加参数，则会取消目前高亮行的选中状态。
      //   this.$refs.learunTable.setCurrentRow(row);
      // },
      clearSort() {
        //用于清空排序条件，数据会恢复成未排序的状态
        this.$refs.learunTable.clearSort();
      },
      clearFilter(columnKey) {
        // 不传入参数时用于清空所有过滤条件，数据会恢复成未过滤的状态，也可传入由columnKey组成的数组以清除指定列的过滤条件
        this.$refs.learunTable.clearFilter(columnKey);
      },
      doLayout() {
        // 对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
        this.$refs.learunTable.doLayout();
      },
      sort(prop) {
        // 手动对 Table 进行排序。参数prop属性指定排序列，order指定排序顺序。prop: string, order: string
        this.$refs.learunTable.sort(prop);
      },
      handleColumnsSettingChange(columns) {
        this.updateColumnsCache(columns);
        this.$emit("columnsSettingChange", columns);
      },
      handleColumnsSettingMoveableChange(columns) {
        this.$emit("columnsSettingMoveableChange", columns);
      },
      rerender() {
        this.isRender = false;
        this.$nextTick(() => {
          this.isRender = true;
        });
      },
      relayout() {
        const elementViewTable = this.$el;
        if (elementViewTable) {
          const centerRows = elementViewTable.querySelectorAll(
            ".el-table__body-wrapper .el-table__body .el-table__row"
          );
          const fixLeftRows = elementViewTable.querySelectorAll(
            ".el-table__fixed .el-table__body .el-table__row"
          );
          const fixRightRows = elementViewTable.querySelectorAll(
            ".el-table__fixed-right .el-table__body .el-table__row"
          );

          if (centerRows) {
            Array.from(centerRows).forEach((centerRow, centerRowIdx) => {
              const centerRowStyleHeight = `${centerRow.offsetHeight}px`;
              if (fixLeftRows[centerRowIdx]) {
                fixLeftRows[centerRowIdx].style.height = centerRowStyleHeight;
              }
              if (fixRightRows[centerRowIdx]) {
                fixRightRows[centerRowIdx].style.height = centerRowStyleHeight;
              }
            });
          }
        }
      },
      handleCloseColumnsSetting() {
        this.rerender();
        this.$emit("closeColumnsSetting");
      },
      updateColumnsCache(columns) {
        if (this.isCacheColumns && this.cacheKey) {
          const columnsCacheKey = this.getColumnsCacheKey(this.cacheKey);
          this.setColumnsCache(columnsCacheKey, columns);
        }
      },
      setColumnsCache(cacheKey, columns) {
        setStore({
          name: cacheKey,
          content: columns
        });
      },
      getColumnsCache(cacheKey) {
        return getStore({
          name: cacheKey
        });
      },
      getColumnsCacheKey(cacheKey) {
        if (cacheKey) {
          return "__COLUMNS__" + cacheKey;
        }
      },
      openColumnsSetting() {
        this.columnsVisible = true;
        this.$emit("openColumnsSetting");
      },
      getRefInRow(ref, rowIndex) {
        const tableBody = this.getTableBody();
        let refName =
          (ref && ref.$vnode && ref.$vnode.data && ref.$vnode.data.ref) || ref;
        if (ref && rowIndex >= 0) {
          if (tableBody) {
            const tableBodyRowElements = Array.from(
              tableBody.$el.children[1].children
            ).filter(
              (item) =>
                item.classList && item.classList.contains("el-table__row")
            );
            const tableBodyChildren = tableBody.$children;

            let foundTableBodyChild;
            tableBodyChildren.find((childChild) => {
              const foundRefChild = _getChildByRefName(childChild, refName);
              if (foundRefChild) {
                const foundRefChildRowIndex = _getChildElementRowIndex(
                  foundRefChild.$el,
                  tableBodyRowElements
                );
                if (rowIndex === foundRefChildRowIndex) {
                  return (foundTableBodyChild = foundRefChild);
                }
              }
            });
            return foundTableBodyChild;
          }
        }
        return ref;

        function _getChildElementRowIndex(ele, rows) {
          let parentEle = ele;
          while (
            parentEle &&
            parentEle.rowIndex == null &&
            parentEle.classList &&
            !parentEle.classList.contains("el-table")
          ) {
            parentEle = parentEle.parentElement;
          }
          if (parentEle) {
            if (
              parentEle.classList &&
              !parentEle.classList.contains("el-table__row")
            ) {
              parentEle = parentEle.previousSibling;
            }
            return rows.indexOf(parentEle);
          }
        }

        function _getChildByRefName(child, refName) {
          if (
            child &&
            child.$vnode &&
            child.$vnode.data &&
            child.$vnode.data.ref &&
            child.$vnode.data.ref.startsWith(refName)
          ) {
            return child;
          } else {
            let foundChild;
            child.$children.find((childChild) => {
              return (foundChild = _getChildByRefName(childChild, refName));
            });
            return foundChild;
          }
        }
      },

      refilter() {
        const tableStore = this.getTableStore();
        tableStore.states.columns.forEach(function (column) {
          column.filteredValue &&
            column.filteredValue.length &&
            tableStore.commit("filterChange", {
              column: column,
              values: column.filteredValue,
              silent: true
            });
        });
      },
      resort() {
        const tableStore = this.getTableStore();
        if (tableStore) {
          tableStore.commit("changeSortCondition", {
            silent: true
          });
        }
      },
      getTable() {
        return this.$refs.learunTable;
      },
      getTableBody() {
        return Array.from(this.getTable().$children).find(
          (child) => child.$options.name === "ElTableBody"
        );
      },
      getTableStore() {
        return this.getTable().store;
      }
    }
  };
}
