import { Notification } from "element-ui";

export const getObjType = (obj) => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object"
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  //return JSON.parse(JSON.stringify(data))
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      data[i] = (() => {
        if (data[i] === 0) {
          return data[i];
        }
        return data[i];
      })();
      if (data[i]) {
        delete data[i].$parent;
      }
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      if (data) {
        delete data.$parent;
      }
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

/**
 * 生成一个uuid
 */
export const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
};

/**
 * 更新对象
 */
export const extend = (result, data) => {
  for (var id in data) {
    result[id] = data[id];
  }
};

/**
 * 比较两个对象的属性值，0相等，-1前者大，1后者大；compare(x).(y1,y2)
 * @param {*} propertyName
 */
export const compare = (propertyName) => {
  return function (object1, object2) {
    var value1 = object1[propertyName];
    var value2 = object2[propertyName];
    if (value2 < value1) {
      return -1;
    } else if (value2 > value1) {
      return 1;
    } else {
      return 0;
    }
  };
};

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = "";
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len ? len : 4);
  if (date) random = random + Date.now();
  return random;
};

/**
 * 下载文件
 * @param {*} prop
 */
export const downFile = (url, title) => {
  Notification({
    title: title || `文件下载成功`,
    type: "success",
    dangerouslyUseHTMLString: true,
    duration: 1000,
    offset: 60,
    //主要是这句，通过ifranme跳过路由标记,这样页面就能自动跳出下载的弹窗了
    message: `<iframe src='${url}' style="display:none"></iframe>`
  });
};

/**
 * 异步方法包裹
 * @param {*} promise
 * @returns
 */
export const awaitWraper = (promise) => {
  return promise.then((res) => res.data.data).catch(() => null);
};

/**
 * 异步方法包裹(调用外部接口)
 * @param {*} promise
 * @returns
 */
export const awaitWraperEx = (promise) => {
  return promise
    .then((res) => {
      return { err: null, data: res };
    })
    .catch((err) => {
      return { err, data: null };
    });
};
/**
 * 表格验证方法包裹
 * @param {*} form
 * @returns
 */
export const formValidateWraper = (form, dValue = true) => {
  return new Promise((resolve) => {
    if (form) {
      form.validate((valid) => {
        resolve(valid);
      });
    } else {
      resolve(dValue);
    }
  });
};

/**
 * 重置表单的值和验证结果
 * @param {*} form
 * @returns
 */
export const formClear = (form) => {
  if (!form) {
    return;
  }
  setTimeout(() => {
    form.resetFields();
    setTimeout(() => {
      form.clearValidate();
    }, 0);
  }, 0);
};

/**
 *
 * @param {*} formData
 * @param {*} data
 * @param {*} tableName
 */
export const setFormData = (formData, data, tableName) => {
  let formProp = "";
  for (var key in data) {
    if (tableName) {
      formProp = `${window.$lowerFirst(tableName)}_${key}`;
    } else {
      formProp = window.$lowerFirst(key);
    }
    formData[formProp] = data[key];
  }
};
/**
 *
 * @param {*} formData
 * @param {*} tableName
 */
export const getFormData = (formData, tableName) => {
  const res = {};
  for (var key in formData) {
    if (key.indexOf(tableName + "_") == 0) {
      let formProp = key.replace(tableName + "_", "");
      res[formProp] = formData[key];
    }
  }
  return res;
};

/**
 *
 * @param {*} fun
 * @returns
 */
export const getFunction = (fun) => {
  if (!window.$validatenull(fun)) {
    try {
      const fn = eval(fun);
      if (typeof fn === "function") {
        return { res: true, msg: "ok", fn };
      } else {
        return { res: false, msg: "此方法不是一个函数" };
      }
    } catch (err) {
      return { res: false, msg: err };
    }
  } else {
    return { res: false, msg: "没设置脚本函数" };
  }
};

export const isIE = () => {
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return true;
  } else {
    return false;
  }
};
