<template>
  <div>
    <el-input
      class="l-input-icon"
      :placeholder="$t(placeholder)"
      v-model="value2"
      :size="size"
      ref="main"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @focus="handleShow"
    >
      <span slot="append" @click="handleShow">
        <el-button slot="append" :icon="value2"></el-button>
      </span>
    </el-input>
    <l-dialog
      :title="$t(placeholder)"
      append-to-body
      :visible.sync="box"
      :class="['l-input-icon-dialog', { 'only-tabs': iconListShow.length <= 1 }]"
      :width='width'
      :height='height'
    >
        <el-tabs v-model="activeName" type="card">
          <template v-for="(item, index) in iconListShow">
            <el-tab-pane
              :key="index"
              :label="item.label"
              :name="'l_' + index"
              style="padding-top: 8px"
            >
              <template v-for="(icon, index2) in item.list">
                <div :key="index2" class="l-input-icon-item">
                  <el-button
                    size="small"
                    :icon="icon"
                    @click="handleSubmit(icon)"
                  ></el-button>
                </div>
              </template>
            </el-tab-pane>
          </template>
        </el-tabs>
    </l-dialog>
  </div>
</template>
<script>
export default {
  name: "l-input-icon",
  props: {
    width: {
      type: [String, Number],
      default: 1200
    },
    height: {
      type: Number,
      default: 600
    },
    value: {},
    placeholder: {
      type: String,
      default: "请选择"
    },
    size: String,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    iconList: Array,
    onlyFirst: Boolean
  },
  data() {
    return {
      box: false,
      activeName: "l_0",
      text: ""
    };
  },
  mounted() {},
  computed: {
    value2: {
      get() {
        return this.value != null ? this.value : this.text;
      },
      set(val) {
        this.text = val;
        this.$emit("input", val);
        this.$emit("change", val);
      }
    },
    top() {
      return (document.body.clientHeight - this.height) / 2;
    },
    iconListShow() {
      let res = [];
      if (this.iconList) {
        res = this.iconList;
      } else if (this.lr_icons) {
        res = this.lr_icons;
      }

      if (this.onlyFirst) {
        res = [res[0]];
      }

      return res;
    }
  },
  methods: {
    handleShow() {
      this.$refs.main.blur();
      if (this.disabled || this.readonly) return;
      this.box = true;
    },
    handleSubmit(item) {
      this.value2 = item;
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.box = false;
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
