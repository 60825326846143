<template>
  <div class="l-checkbox">
    <el-checkbox-group v-model="value2">
      <template v-for="(item, index) in options">
        <el-checkbox
          :disabled="disabled"
          :size="size"
          :key="index"
          :label="item[valueKey]"
          >{{ item[labelKey] }}</el-checkbox
        >
      </template>
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: "l-checkbox",
  props: {
    value: {},
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: Boolean,
    size: String
  },
  data() {
    return {};
  },
  computed: {
    value2: {
      get() {
        if (!this.$validatenull(this.value)) {
          let newValue = (this.value + "").split(",");
          newValue = newValue.map((value) => eval(value));
          return newValue;
        } else {
          return [];
        }
      },
      set(val) {
        let newValue = val.map((value) =>
          typeof value === "string" ? `"${value}"` : value
        );
        newValue = newValue.join(",");

        this.$emit("input", newValue);

        const objs = [];
        if (val.length == 0) {
          this.$emit("change", []);
        } else {
          val.forEach((item) => {
            const objone = this.options.find((t) => t[this.valueKey] == item);
            objs.push(objone);
          });
          this.$emit("change", objs);
        }
      }
    }
  },
  methods: {}
};
</script>
<style lang="less">
@import "./index.less";
</style>
