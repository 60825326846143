<template>
    <component  :is="getComponent(data.type)"
                v-model="value2"
                v-bind="data"
                :placeholder="$t(data.placeholder)"
                :options="myOptions"
                :size="data.size || 'mini'"
                :activeValue="toValueType(data.valueType,data.activeValue)"
                :inactiveValue="toValueType(data.valueType,data.inactiveValue)"  
               >
    </component>
</template>
<script>
export default {
  name:'l-form-item',
  props: {
      value:{},
      data: {
          type: Object,
          default:() => {
              return {}
          }
      },
      formData:Object,
  },
  data () {
    return {
    }
  },
  mounted () {
    this.loadOptions()
  },
  computed:{
    value2:{
      get(){
        return this.value;
      },
      set(val){
        this.$emit('input', val);
      }
    },
    dataType(){
      return this.data.dataType;
    },
    myOptions(){
      // 1 静态数据 2 数据字典 3 远端数据(数据源)
      switch(this.dataType){
          case '1':
              return this.data.options
          case '2':
              if(this.lr_dataItem && this.dataCode){
                  if(this.data.type == 'treeselect'){
                      return this.lr_dataItemTree(this.lr_dataItem[this.dataCode])
                  }
                  else{
                      return this.lr_dataItemOptions(this.lr_dataItem[this.dataCode])
                  }
              }
              else{
                  return []
              }
          case '3':
              if(this.lr_dataSourceData && this.dataCode && this.data.dataValueKey && this.data.dataLabelKey){
                  let dataSource = []
                  if (this.data.upCtrl) {
                      if(this.formData && this.formData[this.data.upCtrl]){
                          const upCtrlVal = this.formData[this.data.upCtrl]
                          this.lr_loadDataSourceDataByParamter(this.dataCode, upCtrlVal)//解决编辑赋值没有触发问题
                          dataSource = this.lr_dataSourceDataByParamter[`${this.dataCode}_${upCtrlVal}`] 
                      }
                      else if(!this.formData){
                          dataSource = this.lr_dataSourceData[this.dataCode]
                      }
                  }
                  else{
                      dataSource = this.lr_dataSourceData[this.dataCode]
                  }

                  if(this.data.type == 'treeselect'){
                      if(this.data.dataIdKey && this.data.dataPIdKey){
                          return this.lr_DataSourceTree(dataSource,this.data.dataIdKey,this.data.dataPIdKey,this.data.dataValueKey,this.data.dataLabelKey)
                      }
                      else{
                          return []
                      }
                  }
                  else{
                      return this.lr_DataSourceOptions(dataSource,this.data.dataValueKey,this.data.dataLabelKey)
                  }
              }
              else{
                  return []
              }
          default:
              return []
      }
    },
  },
  methods:{
    getComponent (type) {
        let result = 'el-input'
        switch(type){
            case 'input':
            case 'textarea':
            case 'password':
                result = 'el-input'
                break;
            case 'number':
                result = 'el-input-number'
                break;
            case 'radio':
                result = 'l-radio'
                break;
            case 'checkbox':
                result = 'l-checkbox'
                break;
            case 'select':
            case 'selectMultiple':
                result = 'l-select'
                break;
            case 'treeselect':
                result =  'l-tree-select'
                break;
            case 'time':
                result = 'l-time'
                break;
            case 'timerange':
                result = 'l-time'
                break;
            case 'datetime':
                result = 'l-date'
                break;
            case 'datetimerange':
                result = 'l-date'
                break;
            case 'companySelect':
                result = 'l-company-select';
                break;
            case 'departmentSelect':
                result = 'l-department-select';
                break;
            case 'userSelect':
                result = 'l-user-select';
                break;
            case 'areaselect':
                result ='l-area-select';
                break;
            case 'layerselect':
                result ='l-layer-select';
                break;
            case 'layerbmap':
                result = 'l-BMap-select';
                break;
        }
        return result
    },
    toValueType(type,value){
      if(!this.$validatenull(value) && !this.$validatenull(type)){
        switch(type){
            case 'boolean':
                return value == 'true';
            case 'string':
                return value;
            case 'number':
                return Number(value);
        }
      }
    },
    loadOptions(){
      if(this.dataType == '2' && this.lr_loadDataItem && this.dataCode){
          this.lr_loadDataItem(this.dataCode)
      }
      else if(this.dataType == '3' && this.lr_loadDataSourceData && this.dataCode){
          if (this.data.upCtrl) {
              if (this.formData && this.formData[this.data.upCtrl]) {
                const upCtrlVal = this.formData[this.data.upCtrl]
                this.lr_loadDataSourceDataByParamter(this.dataCode, upCtrlVal)
              }
              else if(!this.formData){
                this.lr_loadDataSourceDataByParamter(this.dataCode)
              }
          }
          else {
            this.lr_loadDataSourceData(this.dataCode)
          }
      }
    }
  }
}
</script>
