<template>
  <el-tree
    ref="tree"
    class="l-tree"
    :filter-node-method="filterNode"
    :expand-on-click-node="expandOnClickNode"
    :data="options"
    :default-expand-all="defaultExpandAll && !lazy"
    :lazy="lazy"
    :load="handleTreeLoad"
    :node-key="valueKey"
    :current-node-key="value2"
    :props="treeProps"
    @node-click.self="handleNodeClick"
    @check="handleNodeCheck"
  >
    <div class="l-tree-node" slot-scope="{ node, data }">
      <slot :data="data" :node="node"
        ><span class="l-tree-node">
          <i v-if="data.icon" :class="data.icon"></i>
          {{ node.data[labelKey] }}
        </span></slot
      >
    </div>
  </el-tree>
</template>
<script>
import { SHOW_SPLIT } from "@util/variable";
import { validatenull } from "@util/validate";
function bindEvent(safe, name, event) {
  typeof safe[name] === "function" &&
    safe[name]({ value: safe.value, column: safe.column });
  safe.$emit(name, safe.value, event);
}
export default {
  name: "l-tree",
  props: {
    value: {},
    placeholder: String,
    idKey: {
      type: String,
      default: ""
    },
    pidKey: {
      type: String,
      default: ""
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    size: String,
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filter: {
      type: Boolean,
      default: true
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => {
        return [];
      }
    },
    expandOnClickNode: {
      type: Boolean,
      default: false
    },
    parent: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    getValue: Function,
    treeLoad: Function,
    nodeClick: Function,
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: Boolean
  },
  watch: {
    value: {
      handler() {
        this.initValue();
      },
      immediate: true
    },
    options() {
      this.$nextTick(() => {
        this.setLabel();
      });
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      value2: "",
      labelText: [""],
      filterText: ""
    };
  },
  mounted() {},
  computed: {
    labelShow() {
      return (this.labelText || []).join(SHOW_SPLIT).toString();
    },
    treeProps() {
      return {
        label: this.labelKey,
        children: "children",
        disabled: "disabled",
        isLeaf: "isLeaf"
      };
    }
  },
  methods: {
    getCheckedNodes() {
      return this.$refs.tree && this.$refs.tree.getCheckedNodes();
    },
    setCheckedNodes(nodes) {
      this.$refs.tree && this.$refs.tree.setCheckedNodes(nodes);
    },
    getCheckedKeys() {
      return this.$refs.tree && this.$refs.tree.getCheckedKeys();
    },
    setCheckedKeys(keys) {
      this.$refs.tree && this.$refs.tree.setCheckedKeys(keys);
    },
    clearCheckedKeys() {
      this.$refs.tree && this.$refs.tree.setCheckedKeys(null);
    },
    getCurrentNode() {
      return this.$refs.tree && this.$refs.tree.getCurrentNode();
    },
    setCurrentNode(node) {
      this.$refs.tree && this.$refs.tree.setCurrentNode(node);
    },
    getCurrentKey() {
      return this.$refs.tree && this.$refs.tree.getCurrentKey();
    },
    setCurrentKey(key) {
      this.$refs.tree && this.$refs.tree.setCurrentKey(key);
    },
    clearCurrentKey(key) {
      this.$refs.tree && this.$refs.tree.setCurrentKey(null);
    },
    initValue() {
      this.value2 = this.value;
      this.$nextTick(() => {
        this.setLabel();
      });
    },
    handleClear() {
      this.value2 = undefined;
      this.labelText = [];
      this.$emit("input", "");
      this.$emit("clear");
      this.$emit("change", undefined);
    },
    setLabel() {
      if (validatenull(this.value2)) {
        this.labelText = [];
      } else if (this.options && this.$refs.tree) {
        const node = this.$refs.tree.getNode(this.value2);
        if (node) {
          this.labelText = [node.data[this.labelKey]];
        } else if (typeof this.getValue == "function") {
          this.getValue(this.value2, (v) => {
            this.labelText = v;
          });
        } else {
          this.labelText = [];
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleTreeLoad(node, resolve) {
      let callback = (list) => {
        resolve(list);
      };
      this.treeLoad && this.treeLoad(node, callback);
    },
    handleNodeClick(node, data, ref) {
      if (data.disabled) return;
      if (typeof this.nodeClick === "function") this.nodeClick(node, data, ref);
      this.$emit("nodeClick", node, data);
    },
    handleNodeCheck(data, checked) {
      this.$emit("check", data, checked);
    },
    // 初始化滚动条
    handleClick(event) {
      bindEvent(this, "click", event);
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
