/**
 *将数组数据转化成树形数据
 */

import { deepClone } from "./util";

function setGroupMap(data, idKey, pidKey) {
  const groupList = {};
  const map = {};
  data.forEach((item) => {
    groupList[item[pidKey]] = groupList[item[pidKey]] || [];
    groupList[item[pidKey]].push(item);

    map[item[idKey]] = item;
  });

  return {
    group: groupList,
    map: map
  };
}
function setTree(data, allList, idKey, valueKey, labelKey, filterIds) {
  const res = [];
  if (data == null) {
    for (let id in allList.group) {
      if (!filterIds || filterIds.indexOf(id) == -1) {
        if (!allList.map[id]) {
          let list = allList.group[id];
          list.forEach((item2) => {
            if (!filterIds || filterIds.indexOf(item2[idKey]) == -1) {
              item2.value = item2[valueKey];
              item2.label = item2[labelKey];

              if (
                allList.group[item2[idKey]] &&
                allList.group[item2[idKey]].length > 0
              ) {
                item2.children = setTree(
                  allList.group[item2[idKey]],
                  allList,
                  idKey,
                  valueKey,
                  labelKey,
                  filterIds
                );
              }
              res.push(item2);
            }
          });
        }
      }
    }
  } else {
    data.forEach((item) => {
      if (!filterIds || filterIds.indexOf(item[idKey]) == -1) {
        item.value = item[valueKey];
        item.label = item[labelKey];

        if (
          allList.group[item[idKey]] &&
          allList.group[item[idKey]].length > 0
        ) {
          item.children = setTree(
            allList.group[item[idKey]],
            allList,
            idKey,
            valueKey,
            labelKey,
            filterIds
          );
        }
        res.push(item);
      }
    });
  }

  return res;
}

// 将数据转化成树形数据
export function toTree(
  data,
  idKey,
  pidKey,
  valueKey,
  labelKey,
  filterIds,
  noParentIds
) {
  // 数据去重根据value值
  const _data = [];
  let _dataMap = {};

  data.forEach((item) => {
    if (!_dataMap[item[valueKey]]) {
      _dataMap[item[valueKey]] = true;
      _data.push(deepClone(item));
    }
  });

  _dataMap = null;
  if (idKey == pidKey) {
    const res = [];
    _data.forEach((item) => {
      if (!filterIds || filterIds.indexOf(item[idKey]) == -1) {
        item.value = item[valueKey];
        item.label = item[labelKey];
        res.push(item);
      }
    });
    return res;
  }

  const allList = setGroupMap(_data, idKey, pidKey);
  let rootTree = setTree(null, allList, idKey, valueKey, labelKey, filterIds);
  const allowNoParent = noParentIds == null;
  if (!allowNoParent) {
    const newRootTree = [];
    rootTree.forEach((rootItem, rootItemIdx) => {
      const rootItemId = rootItem[idKey];
      const rootItemPid = rootItem[pidKey];
      if (rootItemPid && rootItemPid !== "0") {
        if (noParentIds.indexOf(rootItemId) === -1) {
        } else {
          newRootTree.push(rootItem);
        }
      } else {
        newRootTree.push(rootItem);
      }
    });
    rootTree = newRootTree;
  }
  return rootTree;
}
