<template>
  <div v-loading="loading" element-loading-text="地图加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" ref="mapContainer" class="l-rblock" ></div>
</template>
<script>
import loadBMap from "@util/loadBaiduMap"
export default {
  name: "l-BMap-panel",
  props:{
    address:String,
    lng:Number, // 经度
    lat:Number, // 纬度
  },
  data() {
    return {
      map: null, // 地图实例
      mk: null, // Marker实例
      geolocationControl:null,
      local:null,
      loading:false,
      centerLng:Number,// 经度
      centerLat:Number,// 纬度
    };
  },
  mounted() {
    this.initMap();    // 初始化地图
  },
  methods: {
    // 初始化地图
    async initMap() {
     
      if(this.map){
        if(this.lng && this.lat){
          const point = new window.BMap.Point( this.lng, this.lat );
          this.setPoint(point)
        }
        /*else{
          const point = new window.BMap.Point( this.centerLng,this.centerLat );
          this.setPoint(point)
        }*/
        return
      }
      this.loading = true
      await loadBMap();  // 加载引入BMap

      // 1、挂载地图
      this.map = new window.BMap.Map(this.$refs.mapContainer, { enableMapClick: false });
      var point =  new window.BMap.Point(121.480248,31.236276); //上海定位 后续在做调整
      this.map.centerAndZoom(point, 17);
      
      // 3、设置图像标注并绑定拖拽标注结束后事件
      this.mk = new window.BMap.Marker(point, { enableDragging: true })
      this.map.addOverlay(this.mk)
      this.mk.addEventListener("dragend", (e)=>{
        this.getAddrByPoint(e.point);
      })

      // 4、添加（右上角）平移缩放控件
      this.map.addControl(
        new window.BMap.NavigationControl({
          anchor: window.BMAP_ANCHOR_TOP_RIGHT,
          type: window.BMAP_NAVIGATION_CONTROL_SMALL,
        })
      );

      // 5、添加（左下角）定位控件
      this.geolocationControl = new window.BMap.GeolocationControl({
        anchor: window.BMAP_ANCHOR_BOTTOM_LEFT,
      });
      this.geolocationControl.addEventListener("locationSuccess", (e) =>{
        this.getAddrByPoint(e.point);
      });
      this.geolocationControl.addEventListener("locationError", (e)=> {
        alert(e.message);
      });
      this.map.addControl(this.geolocationControl);

      // 6、绑定点击地图任意点事件
      this.map.addEventListener("click", (e) =>{
        this.getAddrByPoint(e.point);
      });

      // 9、开启鼠标滚轮缩放
      this.map.enableScrollWheelZoom(true);

      this.loading = false

      if(this.lng && this.lat){
        const point = new window.BMap.Point( this.lng, this.lat );
        this.setPoint(point)
      }
    },
    // 逆地址解析函数
    getAddrByPoint(point) {
      var geco = new window.BMap.Geocoder();
      geco.getLocation(point, (res) =>{
        this.mk.setPosition(point);
        this.map.panTo(point);
        
        this.$emit('update:address',res.address)
        this.$emit('update:lng',point.lng)
        this.$emit('update:lat',point.lat)
      })
    },
    // 7-1、地址搜索
    querySearchAsync(str, cb) {
      if(!this.local){
        this.local = new window.BMap.LocalSearch(this.map, {
          onSearchComplete: (res)=> {
            const list = []
            if (res) {
              const poiNum = res.getCurrentNumPois()
              for (let i = 0; i < poiNum; i++) {
                list.push(res.getPoi(i));
              }
            }
            cb(list)
          },
        })
      }
      this.local.search(str);
    },
    // 7-2、选择地址
    setPoint(point) {
      this.mk.setPosition(point);
      this.map.panTo(point);
    },
  },
  beforeDestroy(){
    this.map = null
    this.mk = null
    this.geolocationControl = null
    this.local = null
  },
};
</script>
